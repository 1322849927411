import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { get } from "../services/httpService";
import "./css/Home.css";
import "./css/Products.css";
import { API_URL } from "../helpers/constants";
import cartChicken from "../assets/images/cart-chicken.png";
import ProductDetails from "../components/ProductComponents/ProductDetails";
import Modal from "../components/Modal";

export default function Products(props) {
  //let [shopStored] = sessionStorage.shop ? sessionStorage.shop : null;
  const [categories, setCategories] = useState([]);
  const [presentation, setPresentation] = useState({});
  const [categorySelected, setCategorySelected] = useState({});
  const [modalProductDetails, setModalProductDetails] = useState({
    isOpen: false,
  });

  async function handleCloseProductDetails(reload) {
    setModalProductDetails({ isOpen: false });
    if (reload) {
      window.location.reload();
    }
  }
  function handleOpenModalProdcutDetails(presentation) {
    setPresentation(presentation)
    setModalProductDetails({ isOpen: true });
  }

  let { category } = props.match.params;

  useEffect(() => {
    const routeHome= document.getElementById('routeHome');
    const routeShoppingCart= document.getElementById('routeShoppingCart');
    if(routeHome != null && routeShoppingCart != null){
      routeHome.setAttribute('class', 'nav-link');
      routeShoppingCart.setAttribute('class', 'nav-link');
    }
    getCategories();
  }, [props.shop]);

  async function getCategories() {
    try {
      let shop = JSON.parse(localStorage.shop);
      const response = await get(
        `/api/v1/homeCategories?shop_id=${shop.id_shop}`
      );
      setCategories(response.data);
      if (!category) {
        props.history.push(`/products/${response.data[0].category_slug}`);
      }
    } catch (error) {
      console.log(error);
    }
  }
  if (categories != null && categories != undefined) {
    if (
      categories.length > 0 &&
      category &&
      categorySelected.category_slug !== category
    ) {
      if (!category) {
        setCategorySelected(categories[0]);
      } else {
        const selected = categories.filter((categoryFilter) => {
          return categoryFilter.category_slug == category;
        });
        if (selected.length === 1) {
          setCategorySelected(selected[0]);
        } else {
          props.history.push(`/products/${categories[0].category_slug}`);
        }
      }
    }
  }
  return (
    <Fragment>
      <div className="container px-0">
        <ul className="products-category-list">
          {categories &&
            categories.map((categoryMap) => {
              return (
                <li
                  key={categoryMap.id_category}
                  className={
                    categoryMap.category_slug === categorySelected.category_slug
                      ? "active"
                      : ""
                  }
                >
                  <Link to={`/products/${categoryMap.category_slug}`}>
                    {categoryMap.category_name.toUpperCase()}
                  </Link>
                </li>
              );
            })}
        </ul>
      </div>
      <div className="container">
        <div className="row">
          {categorySelected.presentations &&
            categorySelected.presentations.length === 0 && (
              <div className="col-12 alert alert-danger">
                No hay productos en la categoría seleccionada
              </div>
            )}
          {categorySelected.presentations &&
            categorySelected.presentations.map((presentation) => {
              return (
                <div
                  className="col-12 col-md-4 mb-4 pt-2 presentation"
                  key={presentation.id_presentation}
                >
                  <h4 className="text-center category-title">
                    {presentation.presentation_name.toUpperCase()}
                  </h4>
                  <img
                    src={`${API_URL}/storage/${presentation.presentation_image}`}
                    alt={presentation.presentation_name}
                    className="img-fluid img-presentation"
                  />
                  <button
                    className="mt-2 price-button"
                    onClick={() => { handleOpenModalProdcutDetails(presentation) }}
                    to=""
                  >
                    {new Intl.NumberFormat("es-CO", {
                      style: "currency",
                      currency: "COP",
                      minimumFractionDigits: 0,
                    }).format(
                      presentation.shops[0]
                        ? presentation.shops[0].pivot.presentation_shop_value
                        : 0
                    )}
                    <img
                      src={cartChicken}
                      alt="Carrito de Compras"
                      className="img-fluid"
                      width="50px"
                    />
                    AGREGAR
                  </button>
                </div>
              );
            })}
          {/* <div className="col-12 col-md-4 mb-4 pt-2 category">
                    <h4 className="text-center category-title">MEDIO POLLO</h4>
                    <img src="/static/media/pollocaja.fed9effa.png" alt="Pollo Caja" className="img-fluid"/>
                </div>
                <div className="col-12 col-md-4 mb-4 pt-2 category">
                    <h4 className="text-center category-title">CUARTO DE POLLO</h4>
                    <img src="/static/media/pollocaja.fed9effa.png" alt="Pollo Caja" className="img-fluid"/>
                </div> */}
        </div>
      </div>
      <Modal
        className="product-detail"
        isOpen={modalProductDetails.isOpen}
        onClose={() => handleCloseProductDetails(false)}
      >
        <ProductDetails presentation={presentation} onSave={handleCloseProductDetails}></ProductDetails>
      </Modal>
    </Fragment>
  );
}
