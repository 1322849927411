import React, { Fragment, useEffect, useState } from "react";
import "./css/Home.css";
import UserImage from "./../assets/images/logo-user.png";
import { QueryGet, put } from "../services/httpService";
import HistoryShopUser from "../components/UserOptions/HistoryShopUser";
import StatusUserPurchases from "../components/UserOptions/StatusUserPurchases";
import UserInformation from "../components/UserOptions/UserInformation";
import Modal from "../components/Modal";
import RegisterForm from "../components/AuthForms/RegisterForm";
import LoginForm from "../components/AuthForms/LoginForm";
import PageLoading from '../components/PageLoading';
import ForgotPasswordForm from "../components/AuthForms/ForgotPasswordForm";

export default function Profile() {
  const [modalRegister, setModalRegister] = useState({ isOpen: false });
  const [modalLogin, setModalLogin] = useState({ isOpen: false });
  const [editable, setEditable] = useState(false);
  const [modalForgotPassword, setModalForgotPassword] = useState({ isOpen: false });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(false)
  let [InfoUser, setInfoUser] = useState({
    dataUser: [],
    dataShopsUser: [],
  });
  let [form, setForm] = useState({
    client_first_name: '',
    client_surname: '',
    client_document_number: '',
    email: '',
    client_birthday: '',
    client_document_type: ''
  })
  function handleChangeForm(e) {
    setForm({
      ...form,
      [e.target.name]: e.target.value
    })
  }
  // let [shopInformationData, setDataShopInformation] = useState({
  //   shopData: [],
  // });
  let [informationStatus, setInformationStatus] = useState({
    status: -1,
  });
  let [userState] = useState({
    isLoggedIn: localStorage.getItem("appState") ? JSON.parse(localStorage.getItem("appState")).isLoggedIn : false,
  });
  function handleChange(event) {
    setInformationStatus({
      ...informationStatus,
      [event.target.name]: String(event.target.dataset.id_status),
    });
  }
  async function handleCloseModalRegister(reload) {
    setModalRegister({ isOpen: false });
    if (reload) {
      window.location.reload();
    }
  }

  function handleEditable() {
    if (editable)
      setEditable(false);
    else
      setEditable(true);
  }

  function handleOpenModalRegister() {
    setModalRegister({ isOpen: true });
  }

  function handleOpenModalForgotPassword() {
    setModalForgotPassword({ isOpen: true });
  }

  async function handleCloseModalForgotPassword(close) {
    setModalForgotPassword({ isOpen: false });
    if (close) {
      setModalLogin({ isOpen: false });
    }
  }

  async function handleCloseModalLogin(reload) {
    setModalLogin({ isOpen: false });
    if (reload) {
      window.location.reload();
    }
  }
  function handleOpenModalLogin() {
    setModalLogin({ isOpen: true });
  }

  async function handleSubmit(e) {
    e.preventDefault()
    if (InfoUser.dataUser.data.id_client) {
      try {
        setLoading(true);
        const create_client = await put(`/api/v1/update-client/${InfoUser.dataUser.data.id_client}`, form)
        setLoading(false);
        if (create_client.data) {
          handleEditable();
          window.location.reload();
        } else if (create_client.err) {
          if (create_client.err.response.data.errors.client_email) {
            setMessage(create_client.err.response.data.errors.client_email[0]);
          } else if (create_client.err.response.data.errors.client_document_number) {
            setMessage(create_client.err.response.data.errors.client_document_number[0]);
          } else if (create_client.err.response.data.errors.client_birthday) {
            setMessage(create_client.err.response.data.errors.client_birthday[0]);
          }
        } else {
          setMessage(create_client.message)
        }
      } catch (error) {
        //setMessage(error.message)
        // setIsLoading(false)
      }
    } else {
      setMessage('La contraseña no coincide')
    }
    console.log(InfoUser.dataUser.data.id_client)
  }
  useEffect(() => {
    const loadUserInfo = async () => {
      setLoading(true)
      try {
        if (userState.isLoggedIn) {
          let data = await QueryGet("/api/v1/get-client-by-logged-user");
          let userShopList = data.data.sales.map((sale) => {
            return {
              presentations_shop: sale.presentation_shop.map((presentationTemp) => {
                return {
                  name: presentationTemp.presentation.presentation_name,
                  quantity: presentationTemp.pivot.presentation_shop_sale_quantity
                }
              }),
              date: sale.sale_date,
              value: sale.sale_value,
              id: sale.id_sale
            }
          });
          // Se toman los pedidos con estado 1, 2 y 3
          let dataHashUser = data.data.sales.map((sale) => {
            return {
              hash: ["1","2","3"].includes(sale.sale_status) ? sale.sale_hash : ""
            }
          }).filter((hash) => {
            return hash.hash !== '';
          });
          let hashJoin = "";
          dataHashUser.map((hash) =>{
            if(hash == dataHashUser[Object.keys(dataHashUser)[Object.keys(dataHashUser).length -1]]){ //Valida cuando se esté en el último elemento y no poner un - al final.
              hashJoin += hash.hash
            }
            else{
              hashJoin += hash.hash + "-";
            }
          });
          //Reemplazamos dataShop actual en localStorage por los pedidos de dicho cliente en el establecimiento.
          if (localStorage.getItem("dataShop")) {
            let hashList = localStorage.getItem("dataShop");
            hashList = hashJoin;
            localStorage.setItem("dataShop",hashList);
          } else {
            localStorage.setItem("dataShop", hashJoin);
          }
          setInfoUser({
            ...InfoUser,
            dataShopsUser: userShopList,
            dataUser: data,
          });
          setForm({ //Guardamos datos específicos del cliente para el perfil
            client_first_name: data.data.client_first_name,
            client_surname: data.data.client_surname,
            client_birthday: data.data.client_birthday,
            email: data.data.user.email,
            client_document_number: data.data.client_document_number,
            client_document_type: data.data.client_document_type
          });
          setInformationStatus({
            status: 1
          })
        } else {
          setInformationStatus({
            status: 1
          })
        }
      } catch (err) { }
      setLoading(false)
    };
    loadUserInfo();
  }, [userState.isLoggedIn]);

  if (loading) {
    return <PageLoading />
  }

  return (
    <Fragment>
      {userState.isLoggedIn && InfoUser.dataUser.data ? (
        <div className=" container h-100">
          <div className="card-container bg-yellow">
            <div className="header">
              <a href="#">
                <img src={UserImage} className="img-user"></img>
              </a>
              <h4 className="title-card">
                {InfoUser.dataUser.data.client_first_name} {InfoUser.dataUser.data.client_surname}
              </h4>
            </div>
            {editable ? (
              <form className="description bg-yellow h-100 container" onSubmit={handleSubmit}>
                <ul className="row row-profile">
                  <li className="table-user style-table-user col-5 col-sm-5 col-md-5 col-xl-4">Nombre:</li>
                  <li className="style-table-user col-7 col-sm-7 col-md-7 col-xl-8">
                    <input type="text" className="form-control border-0 w-100 style-input-user" id="client_first_name" name="client_first_name" onChange={handleChangeForm} value={form.client_first_name} required/>
                    {/* {InfoUser.dataUser.data.client_first_name} */}
                  </li>
                </ul>
                <ul className="row row-profile">
                  <li className="table-user style-table-user col-5 col-sm-5 col-md-5 col-xl-4">Apellido:</li>
                  <li className="style-table-user col-7 col-sm-7 col-md-7 col-xl-8">
                    <input type="text" className="form-control border-0 w-100 style-input-user" id="client_surname" name="client_surname" onChange={handleChangeForm} value={form.client_surname} required />
                    {/* {InfoUser.dataUser.data.client_first_name} */}
                  </li>
                </ul>
                <ul className="row row-profile">
                  <li className="table-user style-table-user col-5 col-sm-5 col-md-5 col-xl-4">Tipo de documento:</li>
                  <li className="style-table-user col-7 col-sm-7 col-md-7 col-xl-8">
                    <select className="form-control input-register select-form" id="client_document_type" name="client_document_type" onChange={handleChangeForm} value={form.client_document_type}  required>
                      <option value="" className="select-form">Tipo de documento</option>
                      <option value="CC">Cédula de ciudadanía</option>
                      <option value="CE">Cédula de extranjería</option>
                      <option value="TI">Tarjeta de identidad</option>
                    </select>
                  </li>
                </ul>
                <ul className="row row-profile">
                  <li className="table-user style-table-user col-5 col-sm-5 col-md-5 col-xl-4">Documento:</li>
                  <li className="style-table-user col-7 col-sm-7 col-md-7 col-xl-8">
                    <input type="text" className="form-control border-0 w-100 style-input-user" id="client_document_number" name="client_document_number" onChange={handleChangeForm} value={form.client_document_number}  required/>
                    {/* {InfoUser.dataUser.data.client_document_number} */}
                  </li>
                </ul>
                {/* <ul className="row row-profile">
                  <li className="table-user style-table-user col-5 col-sm-5 col-md-5 col-xl-4">Correo:</li>
                  <li className="style-table-user col-7 col-sm-7 col-md-7 col-xl-8">
                    <input type="email" className="border-0 w-100" id="email" name="email" onChange={handleChangeForm} value={form.email} />
                  </li>
                </ul> */}
                <ul className="row row-profile">
                  <li className="table-user style-table-user col-5 col-sm-5 col-md-5 col-xl-4">Cumpleaños:</li>
                  <li className="style-table-user col-7 col-sm-7 col-md-7 col-xl-8">
                    <input type="date" className="form-control border-0 w-100 style-input-user" id="client_birthday" name="client_birthday" onChange={handleChangeForm} value={form.client_birthday} required/>
                    {/* {InfoUser.dataUser.data.client_birthday} */}
                  </li>
                </ul>
                {message ? <div className="alert alert-danger"> {message} </div> : ''}
                <div className="butto-edit-profile">
                  <button type="submit" className="btn btn-primary btn-user-information" >
                    Actualizar
                  </button>
                </div>
              </form>
            ) : (
              <div className="description bg-yellow h-100 container">
                <ul className="row row-profile">
                  <li className="table-user style-table-user col-5 col-sm-5 col-md-5 col-xl-4">Nombre:</li>
                  <li className="style-table-user col-7 col-sm-7 col-md-7 col-xl-8">
                    {InfoUser.dataUser.data.client_first_name} {InfoUser.dataUser.data.client_surname}
                  </li>
                </ul>
                <ul className="row row-profile">
                  <li className="table-user style-table-user col-5 col-sm-5 col-md-5 col-xl-4">Documento:</li>
                  <li className="style-table-user col-7 col-sm-7 col-md-7 col-xl-8">
                    {InfoUser.dataUser.data.client_document_number}
                  </li>
                </ul>
                <ul className="row row-profile">
                  <li className="table-user style-table-user col-5 col-sm-5 col-md-5 col-xl-4">Correo:</li>
                  <li className="style-table-user col-7 col-sm-7 col-md-7 col-xl-8">
                    {InfoUser.dataUser.data.user.email}
                  </li>
                </ul>
                <ul className="row row-profile">
                  <li className="table-user style-table-user col-5 col-sm-5 col-md-5 col-xl-4">Cumpleaños:</li>
                  <li className="style-table-user col-7 col-sm-7 col-md-7 col-xl-8">
                    {InfoUser.dataUser.data.client_birthday}
                  </li>
                </ul>
                <div className="butto-edit-profile">
                  <button type="button" className="btn btn-primary btn-user-information" onClick={handleEditable} >
                    Editar Perfil
                  </button>
                </div>
              </div>
            )
            }
          </div>
          <ul>
            <li className="seccion-nav">
              <a
                className="footer-nav"
                onClick={handleChange}
                value="1"
                data-id_status="1"
                name="status"
              >
                Pedido
              </a>
            </li>
            <li className="seccion-nav">
              <a
                className="footer-nav"
                onClick={handleChange}
                value="2"
                data-id_status="2"
                name="status"
              >
                Historial
              </a>
            </li>
            <li className="seccion-nav">
              <a
                className="footer-nav"
                onClick={handleChange}
                value="3"
                data-id_status="3"
                name="status"
              >
                Información
              </a>
            </li>
          </ul>
          <hr className="info-user-line" />
          <div className="container row">
            <div className="col-1 space"></div>
            {informationStatus.status == 2 ? (
              <HistoryShopUser
                historyData={InfoUser.dataShopsUser}
              ></HistoryShopUser>
            ) : (
              ""
            )}
            {informationStatus.status == 3 ? (
              <UserInformation
                userLogged={userState.isLoggedIn}
              ></UserInformation>
            ) : (
              ""
            )}

            {informationStatus.status == 1 ? (
              <StatusUserPurchases
                userLogged={userState.isLoggedIn}
              // statusData={shopInformationData.shopData}
              ></StatusUserPurchases>
            ) : (
              ""
            )}
          </div>
          <hr className="info-user-line-footer" />
        </div>
      ) : (
        <div className=" container h-100">
          <div className="card-container bg-yellow">
            <div className="header-user-logout col-4">
              <a href="">
                <img src={UserImage} className="img-user"></img>
              </a>
            </div>
            <div className="form-group col-8 container container-button-perfil">
              <button
                className="btn button-send-information not-logged-user"
                onClick={handleOpenModalLogin}
              >
                Iniciar sesión
              </button>
              <button
                className="btn button-send-information not-logged-user"
                onClick={handleOpenModalRegister}
              >
                Registrate
              </button>
            </div>
            <div>
              <Modal
                className="register-form"
                isOpen={modalRegister.isOpen}
                onClose={() => handleCloseModalRegister(false)}
              >
                <RegisterForm onSave={handleCloseModalRegister}></RegisterForm>
              </Modal>
              <Modal
                className="login-form"
                isOpen={modalLogin.isOpen}
                onClose={() => handleCloseModalLogin(false)}
              >
                <LoginForm onSave={handleCloseModalLogin} onForgot={handleOpenModalForgotPassword} onRegister={handleOpenModalRegister}></LoginForm>
              </Modal>
              <Modal
                className="login-form"
                isOpen={modalForgotPassword.isOpen}
                onClose={() => handleCloseModalForgotPassword(false)}
              >
                <ForgotPasswordForm onSave={handleCloseModalForgotPassword}></ForgotPasswordForm>
              </Modal>
            </div>
          </div>
          <ul>
            <li className="seccion-nav">
              <a
                className="footer-nav"
                onClick={handleChange}
                value="1"
                data-id_status="1"
                name="status"
              >
                Pedido
              </a>
            </li>
          </ul>
          <hr className="info-user-line" />
          <div className="container row">
            <div className="col-1 space"></div>
            {informationStatus.status == 1 ? (
              <StatusUserPurchases
                userLogged={userState.isLoggedIn}
              // statusData={shopInformationData.shopData}
              ></StatusUserPurchases>
            ) : (
              ""
            )}
          </div>
          <hr className="info-user-line-footer" />
        </div>
      )}
    </Fragment>
  );
}
