import React from "react"
import "./css/footer.css"
import location from "../assets/images/location.png"

const Footer = () => {
  return (
    <footer >
      <div className="container-fluid footer-up">
        <div className="container">
          <div className="row py-3">
            <div className="col-12 col-md-4 text-md-start text-center">
              <div className="col-12">
                <img src={location} alt="Location" />
              </div>
              <div className="col-12 footer-city">
                <b>MANIZALES</b>
              </div>
              <div className="col-12">SAN RAFAEL: 8866091 - 3168641438</div>
              <div className="col-12">CONFA DE LA 50: 8850094 - 3173340333</div>
            </div>
            <div className="col-12 col-md-4 text-md-start text-center">
              <div className="col-12">
                <img src={location} alt="Location" />
              </div>
              <div className="col-12 footer-city">
                <b>MEDELLÍN</b>
              </div>
              <div className="col-12">MALL BARICHARA: 5575651 - 3004443753</div>
              <div className="col-12">ENVIGADO: 4084096 - 3017440628</div>
            </div>
            <div className="col-12 col-md-4 text-md-start text-center">
              <div className="col-12">
                <img src={location} alt="Location" />
              </div>
              <div className="col-12 footer-city">
                <b>PUERTO ASÍS</b>
              </div>
              <div className="col-12">322 633 94 45</div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid footer-down">
          <div className="row py-2">
            <div className="col">
              <p className="text-center m-0 p-0">TÉRMINOS Y CONDICIONES - POLÍTICAS DE PROTECCIÓN A LA INFORMACIÓN</p>
            </div>
          </div>
        </div>
    </footer>
  )
}
export default Footer
