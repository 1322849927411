import axios from "axios";
import { API_URL } from "../helpers/constants";

export function httpService() {}

export async function get(url) {
  try {
    const urlFinal = API_URL + url;
    const response = await axios({
      method: "GET",
      url: urlFinal,
    });
    return { data: response.data, err: null };
  } catch (err) {
    return { err: err };
  }
}

export async function post(url, form) {
  try {
    const urlFinal = API_URL + url;
    const response = await axios({
      method: "post",
      url: urlFinal,
      data: form,
    });
    return { data: response.data, err: null };
  } catch (err) {
    return { err: err };
  }
}
export async function QueryGet(url) {
  let state = localStorage.getItem("appState");
  let AppState = JSON.parse(state);
  try {
    const urlFinal = API_URL + url;
    const response = await axios({
      method: "GET",
      url: urlFinal,
      headers: {
        Authorization: "Bearer " + AppState.user.access_token,
      }
    });
    return { data: response.data, err: null };
  } catch (err) {
  }
}
export async function QueryPost(url, form) {
  let state = localStorage.getItem("appState");
  let AppState = JSON.parse(state);
  try {
    const urlFinal = API_URL + url;
    const response = await axios({
      method: "post",
      url: urlFinal,
      headers: {
        Authorization: "Bearer " + AppState.user.access_token,
      },
      data: form,
    });
    return { data: response.data, err: null };
  } catch (err) {
    return { err: err };
  }
}

export async function put(url, form) {
  try {
    const urlFinal = API_URL + url;
    const response = await axios({
      method: "put",
      url: urlFinal,
      data: form,
    });
    return { data: response.data, err: null };
  } catch (err) {
    return { err: err };
  }
}
