import React, { useState } from 'react'
import { API_URL } from '../../helpers/constants';
import axios from 'axios'
import { useHistory } from 'react-router';
import '../css/Register.css';
import { Fragment } from 'react';
import PageLoading from '../PageLoading';

export default function ForgotPasswordForm(props) {

    //const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState(false);
    const [statusSuccess, setStatusSuccess] = useState(false);

    let [form, setForm] = useState({
        email: '',
        password: '',
    })

    function handleChange(e) {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }
    function handleClose(){
        props.onSave(true);
    }

    async function handleSubmit(e) {
        e.preventDefault()
        if (form.email) {
            setLoading(true);
            let userData = {
                email: form.email
            }
            let json = await axios.post(`${API_URL}/api/auth/password/forgot-password`, userData);
            if (json.data.status) {
                setMessage(json.data.message);
                setStatusSuccess(true);
                setLoading(false);
            } else {
                setMessage('Credenciales incorrectas')
            }
        }
    }


    return (
        <form onSubmit={handleSubmit} className="form login">

            {!loading ?
                (
                    <Fragment>
                        <div className="title-login text-center">
                            <h1>Correo de recuperación</h1>
                        </div>
                        {!statusSuccess ? (
                            <Fragment>
                                <div className="form-group">
                                    <input type="email" className="form-control input-register login" id="email" name="email" onChange={handleChange} value={form.email} placeholder="Correo" required />
                                </div>
                                {message ? <div className="alert alert-danger"> {message} </div> : ''}
                                <button type="submit" className="btn button-send-information button-forgot">Enviar correo</button>
                            </Fragment>
                        ) : (
                            <Fragment>
                                {message ? <div className="alert alert-success"> {message} </div> : ''}
                                <button type="submit" className="btn button-send-information not-logged-user button-font-style mt-2" onClick={handleClose}>Cerrar</button>
                            </Fragment>
                        )
                        }
                    </Fragment>
                ) : (
                    <PageLoading />
                )}
        </form>
    )
}