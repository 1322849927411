import React, { Fragment, useEffect, useState } from "react";
import "./css/Home.css";
import "./css/ShoppingCart.css";

import { QueryPost, QueryGet, post } from "../services/httpService";
import tuPedido from "../assets/images/tupedido.png";
import domicilio from "../assets/images/domicilio.png";
import metodopago from "../assets/images/metodopago.png";
import efectivo from "../assets/images/efectivo.png";
import electronico from "../assets/images/electronico.png";
import Modal from "../components/Modal";
import LoginForm from "../components/AuthForms/LoginForm";
import RegisterForm from "../components/AuthForms/RegisterForm";
import ForgotPasswordForm from "../components/AuthForms/ForgotPasswordForm";
import FastShop from "../components/FastShop";
import RegisterAddress from "../components/RegisterAddress";
import RegisterPhone from "../components/RegisterPhone";
import AlertModal from "../components/AlertModal";

export default function ShoppingCart(props) {
  const [modalLogin, setModalLogin] = useState({ isOpen: false });
  const [modalForgotPassword, setModalForgotPassword] = useState({ isOpen: false });
  const [modalRegister, setModalRegister] = useState({ isOpen: false });
  const [modalAlert, setModalAlert] = useState({ 
    isOpen: false , 
    message:'',
    linkToProfile: false
  });
  const [modalFastShop, setModalFastShop] = useState({
    isOpen: false,
    datUserModal: {},
  });
  const [modalRegisterPhone, setModalRegisterPhone] = useState({
    isOpen: false,
  });
  const [modalRegisterAddress, setModalRegisterAddress] = useState({
    isOpen: false,
  });
  let [userState] = useState({
    isLoggedIn: localStorage.getItem("appState") ? JSON.parse(localStorage.getItem("appState")).isLoggedIn : false,
    userData: {},
  });
  let [shoppinCart, setShoppinCart] = useState({
    shoppincartData: localStorage.getItem("LocalPresentations")
      ? JSON.parse(localStorage.getItem("LocalPresentations"))
      : false,
    address: "",
    phone: "",
    observation: "",
    paymentMethod: "",
  });
  let [InfoUser, setInfoUser] = useState({
    addressesUser: [],
    phonesUser: {},
  });
  let [shopValue, setShopValue] = useState({
    shopActiveUser: localStorage.getItem("LocalPresentations") ? true : false,
    totalValue: 0,
    subTotalValue: 0,
    delivery: 0,
  });
  async function handleCloseModalRegister(reload) {
    setModalRegister({ isOpen: false });
    if (reload) {
      window.location.reload();
    }
  }
  function handleOpenModalRegister() {
    setModalRegister({ isOpen: true });
  }

  function handleOpenModalForgotPassword() {
    setModalForgotPassword({ isOpen: true });
  }

  async function handleCloseModalForgotPassword(close) {
    setModalForgotPassword({ isOpen: false });
    if (close) {
      setModalLogin({ isOpen: false });
    }
  }

  function deleteActiveByCart(){
    const routeHome= document.getElementById('routeHome');
    const routeProduct= document.getElementById('routeProduct');
    const routeProfile= document.getElementById('routeProfile');
    if(routeHome != null && routeProduct != null && routeProfile != null ){
      routeHome.setAttribute('class', 'nav-link');
      routeProduct.setAttribute('class', 'nav-link');
      routeProfile.setAttribute('class', 'nav-link');
    }
  }
  useEffect(() => {
    const loadUserInfo = async () => {
      try {
        if(userState.isLoggedIn){
          let data = await QueryGet("/api/v1/get-client-by-logged-user");
          localStorage.setItem("id_client", data.data.id_client);
          let clientAddres = JSON.parse(data.data.client_addresses);
          let clientPhones = JSON.parse(data.data.client_phones);

          let datos3 = clientAddres.addresses ==null ? null :clientAddres.addresses.map((ad) => {
            return ad.address;
          });
          let datos2 = clientPhones.phones ==null ? null : clientPhones.phones.map((dt) => {
            return dt;
          });
          setInfoUser({
            addressesUser: datos3,
            phonesUSer: datos2,
          });
        }
      } catch (err) { }
    };
    loadUserInfo();
    deleteActiveByCart();
  }, [userState.isLoggedIn]);
  useEffect(() => {
    const CalculateValue = () => {
      let totalShop = 0;
      let subTotalShop = 0;
      let deliveryShop = 0;
      if (
        shoppinCart.shoppincartData &&
        shoppinCart.shoppincartData["sale_information"].presentations
      ) {
        let shops =
          shoppinCart.shoppincartData["sale_information"].presentations;
        shops.forEach((element) => {
          subTotalShop += element.presentationTotalValue;
        });
        deliveryShop = shoppinCart.shoppincartData["sale_information"].shop_delivery_value;
        totalShop = subTotalShop + (deliveryShop != undefined ? deliveryShop : 0);
        setShopValue({
          totalValue: totalShop,
          subTotalValue: subTotalShop,
          delivery: deliveryShop,
          shopActiveUser: false,
        });
      }
    };
    CalculateValue();
  }, [shopValue.shopActiveUser]);

  function CalculateValueShop(type, index) {
    let totalShop = 0;
    let subTotalShop = 0;
    let deliveryShop = 0;
    if (
      shoppinCart.shoppincartData &&
      shoppinCart.shoppincartData["sale_information"].presentations
    ) {
      if (index != null || index != undefined) {
        if (type == "Increase") {
          shoppinCart.shoppincartData["sale_information"].presentations[
            index
          ].presentationTotalValue =
            shoppinCart.shoppincartData["sale_information"].presentations[index]
              .presentationTotalValue +
            shoppinCart.shoppincartData["sale_information"].presentations[index]
              .presentation_unit_value;
        } else if (type == "Decrease") {
          shoppinCart.shoppincartData["sale_information"].presentations[
            index
          ].presentationTotalValue =
            shoppinCart.shoppincartData["sale_information"].presentations[index]
              .presentationTotalValue -
            shoppinCart.shoppincartData["sale_information"].presentations[index]
              .presentation_unit_value;
        }
      }
      let shops = shoppinCart.shoppincartData["sale_information"].presentations;
      shops.forEach((element) => {
        subTotalShop += element.presentationTotalValue;
      });
      deliveryShop = shoppinCart.shoppincartData["sale_information"].shop_delivery_value;
      totalShop = subTotalShop + (deliveryShop != undefined ? deliveryShop : 0);
      setShopValue({
        totalValue: totalShop,
        subTotalValue: subTotalShop,
        delivery: deliveryShop,
        shopActiveUser: false,
      });
    }
  }
  function handleChange(event) {
    const phoneSelect = String(event.target.dataset.id_address);
    setShoppinCart({
      ...shoppinCart,
      [event.target.name]: phoneSelect,
    });
  }
  function handleChangeQuantityIncrease(e) {
    shoppinCart.shoppincartData.sale_information.presentations[
      e.target.dataset.product_id
    ].presentation_quantity++;
    CalculateValueShop("Increase", e.target.dataset.product_id);
  }
  function handleChangeQuantityDecrease(e) {
    if (
      shoppinCart.shoppincartData.sale_information.presentations[
        e.target.dataset.product_id
      ].presentation_quantity <= 1
    ) {
      return;
    } else {
      shoppinCart.shoppincartData.sale_information.presentations[
        e.target.dataset.product_id
      ].presentation_quantity--;
      CalculateValueShop("Decrease", e.target.dataset.product_id);
    }
  }
  function handleChangeDeleteProduct(e) {
    shoppinCart.shoppincartData.sale_information.presentations.splice(
      e.target.dataset.product_id,
      1
    );
    localStorage.setItem(
      "LocalPresentations",
      JSON.stringify(shoppinCart.shoppincartData)
    );
    CalculateValueShop();
  }

  function handleChangePaymentMethod(event) {
    const paymentSelected = String(event.target.dataset.payment);
    setShoppinCart({
      ...shoppinCart,
      [event.target.name]: paymentSelected,
    });
  }
  async function handleCloseModalLogin(reload) {
    setModalLogin({ isOpen: false });
    if (reload) {
      window.location.reload();
    }
  }
  async function handleCloseModalRegisterPhone(reload) {
    setModalRegisterPhone({ isOpen: false });
    if (reload) {
      window.location.reload();
    }
  }
  async function handleCloseModalRegisterAddress(reload) {
    setModalRegisterAddress({ isOpen: false });
    if (reload) {
      window.location.reload();
    }
  }
  async function handleCloseModalFastShop(success) {
    setModalFastShop({ isOpen: false });
      setShoppinCart({
        ...shoppinCart,
        shoppincartData: localStorage.getItem("LocalPresentations")
        ? JSON.parse(localStorage.getItem("LocalPresentations"))
        : false
      });
  }
  function handleOpenModalRegisterAddress() {
    setModalRegisterAddress({ isOpen: true });
  }
  function handleOpenModalRegisterPhone() {
    setModalRegisterPhone({ isOpen: true });
  }
  function handleOpenModalFastShop() {
    if(!shoppinCart.shoppincartData) {
      handleOpenModalAlert("Debe ingresar minimo un producto", false);
    }else{
      setModalFastShop({ isOpen: true });
    }
  }
  function handleOpenModalLogin() {
    setModalLogin({ isOpen: true });
  }
  function handleOpenModalAlert(message, toProfile) {
    setModalAlert({ isOpen: true, message: message, linkToProfile: toProfile });
  }
  async function handleCloseModalAlert() {
    setModalAlert({ isOpen: false });
    window.location.reload();
  }
  function handleMoreProductsClick() {
    props.history.push(`/products`);
  }

  async function handleSubmitLoggedUser(e) {
    e.preventDefault();
    var userInfo = JSON.parse(localStorage.getItem("appState"));
    if(!shoppinCart.shoppincartData) {
      handleOpenModalAlert("Debe ingresar minimo un producto", false);
    }else{
      shoppinCart.shoppincartData.sale_information.address = shoppinCart.address;
      shoppinCart.shoppincartData.sale_information.phone = shoppinCart.phone;
      shoppinCart.shoppincartData.sale_information.client_name =
        userInfo.user.name;
    }
    
    let json = await QueryPost(
      `/api/v1/send-order`,
      shoppinCart.shoppincartData
    );
    if (json.data.status) {
      if (localStorage.getItem("dataShop")) {
        let hashList = localStorage.getItem("dataShop");
        hashList = hashList+'-'+json.data.sale.sale_hash;
        localStorage.setItem("dataShop",hashList);
        handleOpenModalAlert("La compra se efectúo exitosamente!", true);
        localStorage.removeItem("LocalPresentations");
        
      } else {
        let hashList = '';
        hashList = json.data.sale.sale_hash;
        localStorage.setItem("dataShop", hashList);
        handleOpenModalAlert("La compra se efectúo exitosamente!", true);
        localStorage.removeItem("LocalPresentations");
        
      }
    }
    setShoppinCart({
      ...shoppinCart,
      shoppincartData: false,
    });
  }

  async function handleSubmitNotLoggedUSer(e) {
    e.preventDefault();
    switch (shoppinCart.shoppincartData) {
      case false:
        handleOpenModalAlert("Debe ingresar minimo un producto", false);
        break;
      default:
        if(shoppinCart.shoppincartData.sale_information.client_name == "" 
        || shoppinCart.shoppincartData.sale_information.phone == "" 
        || shoppinCart.shoppincartData.sale_information.address == ""){
          handleOpenModalAlert("Debe ingresar los datos de usuario para realizar la compra", false);
          //alert("Debe ingresar los datos de usuario para realizar la compra");
        } else{
            let json = await post(
              `/api/v1/send-order`,
              shoppinCart.shoppincartData
            );
            if (json.data.status) {
              if (localStorage.getItem("dataShop")) {
                let hashList = '';
                let localDataHash = localStorage.getItem("dataShop");
                hashList = localDataHash;
                hashList = hashList + '-' + json.data.sale.sale_hash;
                localStorage.setItem("dataShop", hashList);
                handleOpenModalAlert("La compra se efectúo exitosamente!", true);
                localStorage.removeItem("LocalPresentations");
              } else {
                let hashList = '';
                hashList = json.data.sale.sale_hash;
                localStorage.setItem("dataShop", hashList);
                handleOpenModalAlert("La compra se efectúo exitosamente!", true);
                localStorage.removeItem("LocalPresentations");
              }
            }
          }
       break;
      }
    /* localStorage.removeItem("LocalPresentations"); */
    /* setShoppinCart({
      ...shoppinCart,
      shoppincartData: false,
    }); */
  }

  return (
    <Fragment>
      <div className="container">
        <div className="container-title-shopping-cart">
          <div className="img-title-shopping-cart">
            <img src={tuPedido} alt="" className="img-fluid" />
          </div>
        </div>
        <div className="table-container-shoppingcart container">
          {shoppinCart.shoppincartData &&
            shoppinCart.shoppincartData["sale_information"].presentations &&
            shoppinCart.shoppincartData["sale_information"].presentations.map(
              (product) => {
                return (
                  <ul
                    className="product-list-information"
                    key={shoppinCart.shoppincartData[
                      "sale_information"
                    ].presentations.indexOf(product)}
                  >
                    <li className="row">
                      <hr className="separator-shopping-cart mt-2 mb-2" />
                      <label className="col-4 col-sm-4 col-md-3 shop-information shop-information-letter">
                        {product.presentation_name}
                      </label>
                      <label className="col-4 col-sm-3 col-md-4 shop-information shop-information-letter justify-content-center">
                        {new Intl.NumberFormat("es-CO", {
                          style: "currency",
                          currency: "COP",
                          minimumFractionDigits: 0,
                        }).format(
                          product.presentationTotalValue
                            ? product.presentationTotalValue
                            : 0
                        )}
                      </label>
                      <div className="col-4 col-sm-4 offset-sm-1 offset-md-0 col-md-5 row">
                        <label className="col-12 col-xl-5 shop-information shop-information-letter justify-content-center">
                          <ul className="d-flex change-quantity-product">
                            <li className="li-change-shopping">
                              <a
                                className="quantity-change-shoppingcart"
                                name="presentation_quantity"
                                value={product}
                                data-product_id={shoppinCart.shoppincartData[
                                  "sale_information"
                                ].presentations.indexOf(product)}
                                onClick={handleChangeQuantityDecrease}
                              >
                                -
                              </a>
                            </li>
                            <li className="product-quantity li-change-shopping">
                              {product.presentation_quantity}
                            </li>
                            <li  className="li-change-shopping">
                              <a
                                className="quantity-change-shoppingcart"
                                data-product_id={shoppinCart.shoppincartData[
                                  "sale_information"
                                ].presentations.indexOf(product)}
                                name="presentation_quantity"
                                onClick={handleChangeQuantityIncrease}
                              >
                                +
                              </a>
                            </li>
                          </ul>
                        </label>
                        <label className="col-11 col-xl-7 delete">
                          <a
                            className="delete-product"
                            data-product_id={shoppinCart.shoppincartData[
                              "sale_information"
                            ].presentations.indexOf(product)}
                            name="presentation_quantity"
                            onClick={handleChangeDeleteProduct}
                          >
                            Eliminar
                          </a>
                        </label>
                      </div>
                    </li>
                  </ul>
                );
              }
            )}
          <ul className="total-products-value">
            <li className="row">
              <hr className="separator-shopping-cart mb-2 mt-2" />
              <label className="col-3 shop-information shop-information-letter text-center total-shop">
                Subtotal
              </label>
              <label className="col-4 shop-information shop-information-letter text-center justify-content-center">
                {new Intl.NumberFormat("es-CO", {
                  style: "currency",
                  currency: "COP",
                  minimumFractionDigits: 0,
                }).format(
                  shopValue.subTotalValue ? shopValue.subTotalValue : 0
                )}
              </label>
            </li>
            <li className="row">
              <label className="col-3 shop-information shop-information-letter text-center total-shop">
                Domicilio
              </label>
              <label className="col-4 shop-information shop-information-letter text-center justify-content-center">
                {new Intl.NumberFormat("es-CO", {
                  style: "currency",
                  currency: "COP",
                  minimumFractionDigits: 0,
                }).format(shopValue.delivery ? shopValue.delivery : 0)}
              </label>
              <button
                className="btn btn-add-products col-3 offset-2"
                onClick={() => handleMoreProductsClick()}
              >
                MÁS PRODUCTOS
              </button>
            </li>
            <li className="row">
              <label className="col-3 shop-information shop-information-letter text-center total-shop">
                Total
              </label>
              <label className="col-4 shop-information shop-information-letter text-center justify-content-center">
                {new Intl.NumberFormat("es-CO", {
                  style: "currency",
                  currency: "COP",
                  minimumFractionDigits: 0,
                }).format(shopValue.totalValue ? shopValue.totalValue : 0)}
              </label>
              <hr className="separator-shopping-cart mt-1" />
            </li>
          </ul>
        </div>
        <div>
          <img src={domicilio} alt="" className="img-fluid" />
        </div>
        {userState.isLoggedIn ? (
          <Fragment>
            <div className="form-group col-12">
              <label>Direcciones</label>
              <div className="list-group">
                {InfoUser.addressesUser &&
                  InfoUser.addressesUser.map((dato) => {
                    return (
                      <a
                        className="list-group-item infor-user"
                        key={dato}
                        value={dato}
                        name="address"
                        data-id_address={dato}
                        onClick={handleChange}
                        id="address"
                      >
                        {dato}
                      </a>
                    );
                  })}
                <div className="col-12 d-flex justify-content-center mt-2">
                  <button
                    className="btn button-send-information button-shopping-information"
                    onClick={handleOpenModalRegisterAddress}
                  >
                    Agregar Dirección
                  </button>
                </div>
              </div>
            </div>
            <div className="spacer5"></div>
            <div className="form-group col-12">
              <label>Telefonos</label>
              <div className="list-group">
                {InfoUser.phonesUSer &&
                  InfoUser.phonesUSer.map((dato) => {
                    return (
                      <a
                        className="list-group-item infor-user"
                        key={dato}
                        value={dato}
                        name="phone"
                        data-id_address={dato}
                        onClick={handleChange}
                        id="phone"
                      >
                        {dato}
                      </a>
                    );
                  })}
                <div className="col-12 d-flex justify-content-center mt-2">
                  <button
                    className="btn button-send-information button-shopping-information"
                    onClick={handleOpenModalRegisterPhone}
                  >
                    Agregar Telefono
                  </button>
                </div>
              </div>
            </div>
            {/* <div className="form-group col-12">
              <label>
                Observaciones
                <textarea
                  name="observation"
                  onChange={handleChange}
                  value={shoppinCart.observation}
                  className="form-control input-register-shoppincart shopping-cart-text-area"
                />
              </label>
            </div> */}
            <div className="row">
              <div className="col-12 imagen-payment-mthod">
                <img src={metodopago} alt="" className="img-fluid" />
              </div>
            </div>
            <div className="row container-payment">
              <div className="col-3">
                <h3 className="text-center category-title">EFECTIVO</h3>
                <a
                  onClick={handleChangePaymentMethod}
                  value={shoppinCart.paymentMethod}
                >
                  <img src={efectivo} alt="" name="paymentMethod" data-payment={1} className={`img-fluid imagen-pay payment-method-icon${shoppinCart.paymentMethod == 1 ? "-active" : ""}`}/>
                </a>
              </div>
              {/* <div className="col-3">
                <h3 className="text-center category-title">ELECTRÓNICO</h3>
                <a
                  onClick={handleChangePaymentMethod}
                  name="paymentMethod"
                  value={shoppinCart.paymentMethod}
                >
                  <img
                    src={electronico}
                    alt=""
                    className="img-fluid imagen-pay"
                  />
                </a>
              </div> */}
            </div>
            <div className="row">
              <div className="col justify-content-center d-flex">
                <button type="submit" className="btn  button-send-request button-font-style-order mt-2" onClick={handleSubmitLoggedUser}>Hacer Pedido</button>
              </div>
            </div>
            <div className="form-group col-12">
              <Modal
                className="login-form"
                isOpen={modalRegisterPhone.isOpen}
                onClose={() => handleCloseModalRegisterPhone(false)}
              >
                <RegisterPhone
                  onSave={handleCloseModalRegisterPhone}
                ></RegisterPhone>
              </Modal>
            </div>
            <div className="form-group col-12">
              <Modal
                className="login-form"
                isOpen={modalRegisterAddress.isOpen}
                onClose={() => handleCloseModalRegisterAddress(false)}
              >
                <RegisterAddress
                  onSave={handleCloseModalRegisterAddress}
                ></RegisterAddress>
              </Modal>
            </div>
            <div className="form-group col-12">
              <Modal
                className="alert-form"
                isOpen={modalAlert.isOpen}
                onClose={() => handleCloseModalAlert(false)}
              >
                <AlertModal
                  message ={modalAlert.message}
                  linkToProfile = {modalAlert.linkToProfile}
                  onSave={handleCloseModalAlert}
                ></AlertModal>
              </Modal>
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <div className="form-group col-12">
              <button
                className="btn button-send-information not-logged-user"
                onClick={handleOpenModalLogin}
              >
                Iniciar sesión
              </button>
            </div>
            {!userState.isLoggedIn && userState.userData.name ? (
              <div>
                <ul className="list-group">
                  <li className="list-group-item">
                    Nombre: {userState.userData.name}
                  </li>
                  <li className="list-group-item">
                    Direccion: {userState.userData.address}
                  </li>
                  <li className="list-group-item">
                    Telefono: {userState.userData.phone}
                  </li>
                </ul>
                <div className="form-group col-12">
                  <button
                    className="btn button-send-information not-logged-user"
                    onClick={handleOpenModalFastShop}
                  >
                    Modificar información
                  </button>
                </div>
              </div>
            ) : (
              <div className="form-group col-12">
                <button
                  className="btn button-send-information not-logged-user"
                  onClick={handleOpenModalFastShop}
                >
                  Compra rápida
                </button>
              </div>
            )}
            <div className="form-group col-12">
              <Modal
                className="login-form"
                isOpen={modalLogin.isOpen}
                onClose={() => handleCloseModalLogin(false)}
              >
                <LoginForm onSave={handleCloseModalLogin} onForgot={handleOpenModalForgotPassword} onRegister={handleOpenModalRegister}></LoginForm>
              </Modal>
            </div>
            <div className="form-group col-12">
              <Modal
                className="login-form"
                isOpen={modalFastShop.isOpen}
                onClose={() => handleCloseModalFastShop(false)}
              >
                <FastShop onSave={handleCloseModalFastShop}></FastShop>
              </Modal>
            </div>
            <div className="form-group col-12">
              <Modal
                  className="login-form"
                  isOpen={modalForgotPassword.isOpen}
                  onClose={() => handleCloseModalForgotPassword(false)}
                >
                  <ForgotPasswordForm onSave={handleCloseModalForgotPassword}></ForgotPasswordForm>
                </Modal>
            </div>
            <div className="form-group col-12">
              <Modal
                  className="register-form"
                  isOpen={modalRegister.isOpen}
                  onClose={() => handleCloseModalRegister(false)}
                >
                  <RegisterForm onSave={handleCloseModalRegister}></RegisterForm>
                </Modal>
            </div>
            <div className="form-group col-12">
              <Modal
                className="alert-form"
                isOpen={modalAlert.isOpen}
                onClose={() => handleCloseModalAlert(false)}
              >
                <AlertModal
                  message ={modalAlert.message}
                  linkToProfile = {modalAlert.linkToProfile}
                  onSave={handleCloseModalAlert}
                ></AlertModal>
              </Modal>
            </div>
            <div className="row">
              <div className="col-12 imagen-payment-mthod">
                <img src={metodopago} alt="" className="img-fluid" />
              </div>
            </div>
            <div className="row container-payment">
              <div className="col-3">
                <h3 className="text-center category-title">EFECTIVO</h3>
                <a
                  onClick={handleChangePaymentMethod}
                  value={shoppinCart.paymentMethod}
                >
                  <img src={efectivo} alt="" name="paymentMethod" data-payment={1} className={`img-fluid imagen-pay payment-method-icon${shoppinCart.paymentMethod == 1 ? "-active" : ""}`}/>
                </a>
              </div>
              {/* <div className="col-3">
                <h3 className="text-center category-title">ELECTRÓNICO</h3>
                <a
                  onClick={handleChangePaymentMethod}
                  name="paymentMethod"
                  value={shoppinCart.paymentMethod}
                >
                  <img
                    src={electronico}
                    alt=""
                    className="img-fluid imagen-pay"
                  />
                </a>
              </div> */}
            </div>
            <div className="row">
              <div className="col justify-content-center d-flex">
                <button type="submit" className="btn  button-send-request button-font-style-order mt-2" onClick={handleSubmitNotLoggedUSer}>Hacer Pedido</button>
              </div>
            </div>
          </Fragment>
        )}
      </div>
    </Fragment>
  );
}
