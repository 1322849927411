import React, { useState } from 'react'
import { useHistory } from 'react-router';
import { post } from '../../services/httpService'
import axios from 'axios'
import { API_URL } from '../../helpers/constants';
import '../css/Register.css';
import PageLoading from '../PageLoading';
import { Fragment } from 'react';

export default function ResetPasswordForm(props) {

    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState(false);
    const [statusSuccess, setStatusSuccess] = useState(false);
    let [form, setForm] = useState({
        email: '',
        password: '',
        password_confirmation: '',
        token: ''
    })

    function handleChange(e) {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    async function goBackHome(){
        await loginUser();
    }

    async function handleSubmit(e) {
        e.preventDefault()
        if (props.match.params != undefined) {
            form.token = props.match.params.token;
        }
        if (form.password === form.password_confirmation) {
            try {
                console.log(form)
                setLoading(true);
                let reset_response = await post(`/api/auth/password/reset`, form);
                setLoading(false);
                if (reset_response.data) {
                    if(reset_response.data.status){
                        setMessage(reset_response.data.message);
                        setStatusSuccess(true);
                    }else{
                        setMessage(reset_response.data.message);
                    }
                }else if (reset_response.err) {
                    reset_response.err.response.data.map((message) => {
                        setMessage(message);
                    });
                }
            } catch (error) {
                console.log(error);
            }
        } else {
            setMessage('La contraseña no coincide')
        }
    }

    async function loginUser() {
        if (form.email && form.password) {
            let userData = {
                email: form.email,
                password: form.password
            }
            let json = await axios.post(`${API_URL}/api/auth/login`, userData);
            userData = {
                id: json.data.id,
                name: json.data.name,
                email: json.data.email,
                access_token: json.data.access_token,
            };
            let appState = {
                isLoggedIn: true,
                user: userData,
            };
            localStorage["appState"] = JSON.stringify(appState);
            
            history.push("/");
            window.location.reload();
        }
    }


    return (
        <div className="register-form container mt-5 mb-5 container-form-reset-password">
            <h1 className="text-center">Recuperar contraseña</h1>
            <form onSubmit={handleSubmit} className="form">
                {!loading ?
                    (
                        <Fragment>
                            {!statusSuccess ? (
                                <Fragment>
                                    <div className="form-group">
                                        <input className="form-control input-register" type="email" id="email" name="email" onChange={handleChange} value={form.email} placeholder="Email" required />
                                    </div>
                                    <div className="form-group">
                                        <input className="form-control input-register" type="password" id="password" name="password" onChange={handleChange} value={form.password} placeholder="Contraseña" required />
                                    </div>
                                    <div className="form-group">
                                        <input className="form-control input-register" type="password" id="password_confirmation" name="password_confirmation" onChange={handleChange} value={form.password_confirmation} placeholder="Confirmar contraseña" required />
                                    </div>
                                    {message ? <div className="alert alert-danger"> {message} </div> : ''}

                                    <button type="submit" className="btn button-send-information button-recover">Recuperar contraseña</button>
                                </Fragment>
                            ) : (
                                <Fragment>
                                    {message ? <div className="alert alert-success text-center "> {message} </div> : ''}
                                    <button type="submit" className="btn button-send-information not-logged-user button-font-style" onClick={goBackHome}>Cerrar</button>
                                </Fragment>
                            )
                            }
                        </Fragment>
                    ) : (
                        <PageLoading />
                    )}
            </form>
        </div>

    )
}