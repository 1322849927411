import React from 'react';
import "./css/Register.css";

export default function AlertModal(props) {

    async function handleCloseModal(e) {
        props.onSave();
    }
    return (
        <form className="form login">
            <div className="title-login text-center title-direction">
                <h1>{props.message}</h1>
                {props.linkToProfile ?
                 <h5>Para hacer seguimiento al pedido, da click <a href="/profile" style={{ color: 'white' }}> aquí</a></h5>
                : ''
                }
            </div>
            <button type="submit" className="btn button-send-information not-logged-user button-font-style mt-2" onClick={handleCloseModal}>Cerrar</button>
        </form>
    )
}
