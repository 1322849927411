import React, { useState } from 'react'
import { API_URL } from '../../helpers/constants';
import axios from 'axios'
import { useHistory } from 'react-router';
import '../css/Register.css';
import { Fragment } from 'react';
import PageLoading from '../PageLoading';

export default function LoginForm(props) {

    //const history = useHistory();
    const[loading, setLoading] = useState(false);
    const [message, setMessage] = useState(false)

    let [form, setForm] = useState({
        email: '',
        password: '',
    })

    function handleChange(e) {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    async function handleSubmit(e) {
        e.preventDefault()
        if (form.email && form.password) {
            setLoading(true);
            let userData = {
                email: form.email,
                password: form.password
            }
            try{
                let json = await axios.post(`${API_URL}/api/auth/login`, userData);
                if (json.status == 200 && json.data.rol == 3) {
                    userData = {
                        id: json.data.id,
                        name: json.data.name,
                        email: json.data.email,
                        access_token: json.data.access_token,
                    };
                    let appState = {
                        isLoggedIn: true,
                        user: userData,
                    };
                    localStorage["appState"] = JSON.stringify(appState);
                    setLoading(false);
                    props.onSave(true)
                } else {
                    setLoading(false);
                    setMessage('Credenciales incorrectas')
                }
            } catch(error){
                setLoading(false);
                setMessage('Credenciales incorrectas')
            }
            
        }
    }


    return (
        <form onSubmit={handleSubmit} className="form login">
            
            {!loading?
            (
            <Fragment>
            <div className="title-login text-center">
                <h1>Identifícate</h1>
            </div>
                <div className="form-group">
                <input type="email" className="form-control input-register login" id="email" name="email" onChange={handleChange} value={form.email} placeholder="Correo" required />
            </div>
            <div className="form-group">
                <input type="password" className="form-control input-register login" id="password" name="password" onChange={handleChange} value={form.password} placeholder="Contraseña" required />
            </div>
            {message ? <div className="alert alert-danger"> {message} </div> : ''}
            <div className="form-group form-check check-robot row">
                <input type="checkbox" className="form-check-input col-2" id="exampleCheck1" />
                <label className="form-check-label message-check col-4">Recordarme</label>
                <label className="form-check-label message-check col-6"><a class="forgotPassword-a" onClick={props.onForgot}>¿Olvidaste tu contraseña?</a></label>
            </div>
            <button type="submit" className="btn button-send-information button-login">Iniciar sesión</button>
            <label className="form-check-label message-check-register col-12 text-center">¿No eres miembro? <a onClick={props.onRegister} className="link-login">Regístrate</a></label>
            </Fragment> 
            ):(
                <PageLoading />
            )}            
        </form>
    )
}