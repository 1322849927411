import React from 'react'

import './css/PageLoading.css'
import loaderImg from '../assets/images/Loader-mac.gif'

export default function PageLoading() {
    return <div className="PageLoading">
          <img className="img-fluid" src={loaderImg} alt="Loader-mac"/>
        {/* <Loader /> */}
    </div>
}

