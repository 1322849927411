import React, { useState } from 'react'
import { useHistory } from 'react-router';
import { post } from '../../services/httpService'
import axios from 'axios'
import { API_URL } from '../../helpers/constants';
import '../css/Register.css';
import PageLoading from '../PageLoading';
import { Fragment } from 'react';

export default function RegisterForm(props) {

    const history = useHistory();
    const[loading, setLoading] = useState(false);
    const [message, setMessage] = useState(false)
    let [form, setForm] = useState({
        client_name: '',
        client_surname: '',
        client_email: '',
        password: '',
        password_confirmation: '',
        client_document_type: '',
        client_document_number: '',
        client_birthday: ''
    })

    function handleChange(e) {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }
    function _onFocus(e){
    e.currentTarget.type = "date";
    }

    function _onBlur(e){
        e.currentTarget.type = "text";
        e.currentTarget.placeholder = "CUMPLEAÑOS";
    }

    async function handleSubmit(e) {
        e.preventDefault()
        if (form.password === form.password_confirmation) {
            try {
                setLoading(true);
                const create_client = await post(`/api/v1/register-client`, form)
                setLoading(false);
                if (create_client.data) {
                    await loginUser(form.client_email, form.password)
                    props.onSave(true)
                } else if(create_client.err){
                    if(create_client.err.response.data.errors.client_email){
                        setMessage(create_client.err.response.data.errors.client_email[0]);
                    } else if(create_client.err.response.data.errors.client_document_number){
                        setMessage(create_client.err.response.data.errors.client_document_number[0]);
                    } else if(create_client.err.response.data.errors.client_birthday){
                        setMessage(create_client.err.response.data.errors.client_birthday[0]);
                    }
                } else {
                    setMessage(create_client.message)
                }
            } catch (error) {
                //setMessage(error.message)
                // setIsLoading(false)
            }
        } else {
            setMessage('La contraseña no coincide')
        }
    }

    async function loginUser(email, password) {
        if (email && password) {
            let userData = {
                email: email,
                password: password
            }
            let json = await axios.post(`${API_URL}/api/auth/login`, userData);
            userData = {
                id: json.data.id,
                name: json.data.name,
                email: json.data.email,
                access_token: json.data.access_token,
            };
            let appState = {
                isLoggedIn: true,
                user: userData,
            };
            localStorage["appState"] = JSON.stringify(appState);
            history.push("/");
            
        }
    }

    // if (isLoading) {
    //     return <MiniLoader />
    // }

    return (
        <div className="register-form">
            <h1 className="text-center">Regístrate</h1>
        <form onSubmit={handleSubmit} className="form">
        {!loading?
            (
            <Fragment>
                <div className="row">
                    <div className="form-group col-6">
                        <select className="form-control input-register select-form" id="client_document_type" name="client_document_type" onChange={handleChange} value={form.client_document_type} placeholder="Tipo de documento" required>
                            <option value="" className="select-form">Tipo de documento</option>
                            <option value="CC">Cédula de ciudadanía</option>
                            <option value="CE">Cédula de extranjería</option>
                            <option value="TI">Tarjeta de identidad</option>
                        </select>
                    </div>
                    <div className="form-group col-6">
                        <input className="form-control input-register" type="number" id="client_document_number" name="client_document_number" onChange={handleChange} value={form.client_document_number} placeholder="Número de documento" required />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-6">
                        <input className="form-control input-register" type="text" id="client_name" name="client_name" onChange={handleChange} value={form.client_name} placeholder="Nombre" required />
                    </div>
                    <div className="form-group col-6">
                        <input className="form-control input-register" type="text" id="client_surname" name="client_surname" onChange={handleChange} value={form.client_surname} placeholder="Apellido" required />
                    </div>
                </div>
                <div className="form-group">
                    <input className="form-control input-register" type="email" id="client_email" name="client_email" onChange={handleChange} value={form.client_email} placeholder="Email" required />
                </div>
                <div className="form-group">
                    <input className="form-control input-register" type="text" onFocus = {_onFocus} onBlur={_onBlur} id="client_birthday" name="client_birthday" onChange={handleChange} value={form.client_birthday} placeholder="Cumpleaños" required />
                </div>

                <div className="form-group">
                    <input className="form-control input-register" type="password" id="password" name="password" onChange={handleChange} value={form.password} placeholder="Contraseña" required />
                </div>
                <div className="form-group">
                    <input className="form-control input-register" type="password" id="password_confirmation" name="password_confirmation" onChange={handleChange} value={form.password_confirmation} placeholder="Confirmar contraseña" required />
                </div>
                {/* <div className="form-group form-check check-robot-1">
                    <input type="checkbox" className="form-check-input" id="exampleCheck1" />
                    <label className="form-check-label message-check">No soy un robot</label>
                </div> */}
                <div className="form-group form-check check-robot">
                    <input type="checkbox" className="form-check-input" id="exampleCheck1" />
                    <label className="form-check-label message-check">Desea recibir los correos con las promociones de mac rooster</label>
                </div>
                <div className="form-group form-check check-robot">
                    <input type="checkbox" className="form-check-input" id="exampleCheck1" />
                    <label className="form-check-label message-check">Acepto la politica de privacidad y uso de este sitio web</label>
                </div>
                {message ? <div className="alert alert-danger"> {message} </div> : ''}
                
                <button type="submit" className="btn button-send-information button-register">Completar Registro</button>
            </Fragment> 
            ):(
                <PageLoading />
            )}  
        </form>
        </div>

    )
}