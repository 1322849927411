import React, { useState, useEffect } from "react";
import { Fragment } from "react";
import '../css/userOptions.css';

export default function HisotyShopUser(props) {
  const [message, setMessage] = useState("Aún no tienes pedidos en historial, haz compras y luego podras volver a esta sección a revisar tus compras pasadas");
  let [historyPurchasesUSer] = useState({
    purchases: props.historyData,
  });


  return (
    <Fragment>
      <ul className="ul-history-profile">
        {historyPurchasesUSer.purchases.length > 0 ?
          (<li className="row li-history-profile">
            <label className="col-1 value-history color-history-profile pe-1">Compra</label>
            <label className="col-6 shop-information justify-content-center color-history-profile">Productos</label>
            <label className="col-3 shop-information justify-content-center color-history-profile">Fecha</label>
            <label className="col-2 value-history color-history-profile">Valor Productos</label>
            {/* <label>Valor Domicilio</label> */}
          </li>
          ) :
          (
            <li>
              <div className="alert alert-warning" style={{ color: 'var(--red-macrooster)', background: 'var(--yellow-macrooster)'}}> {message} </div>
            </li>
          )
        }
        {historyPurchasesUSer.purchases &&
          historyPurchasesUSer.purchases.map((purchase) => {
            return (
              <li className="row li-history-profile" key={purchase.id}>
                <label className="col-1 value-history">{purchase.id}</label>
                <label className="col-6 shop-information justify-content-center">
                  {purchase.presentations_shop.map((presentation) => {
                    return (
                      <Fragment key={purchase.presentations_shop.indexOf(presentation)}>
                        • {presentation.name} {presentation.quantity} <br />
                      </Fragment>
                    );
                  })}
                </label>
                <label className="col-3 shop-information justify-content-center">
                  {(new Date(purchase.date)).getDate() + "/" + ((new Date(purchase.date)).getMonth() + 1 < 10 ? ('0' + ((new Date(purchase.date)).getMonth() + 1)) : (new Date(purchase.date)).getMonth() + 1) + "/" + (new Date(purchase.date)).getFullYear()}
                </label>
                <label className="col-2 value-history">${purchase.value}</label>
                {/* <hr className="separator-shopping-cart" /> */}
              </li>
            );
          })}
      </ul>
    </Fragment>
  );
}
