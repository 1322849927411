import React, { useState, useEffect } from "react";
import { Fragment } from "react";
import '../css/userOptions.css';
import { get } from "../../services/httpService";
import PageLoading from '../PageLoading';

export default function StatusUserPurchases(props) {
  // let [statusShop, setStatausShoo] = useState({
  //   data: props.statusData,
  // });
  const [message, setMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingFirst, setLoadingFirst] = useState(false);
  let [shopInformationData, setDataShopInformation] = useState({
    shopData: [],
  });
  let [loggedUser] = useState({
    logged: props.userLogged,
  });
  function deleteSaleHash(hash){
    if (localStorage.getItem("dataShop")) {
      let shopData = localStorage.getItem("dataShop");
      let arraySaleHash = shopData.split('-');
      arraySaleHash.splice(arraySaleHash.indexOf(hash),1);
      if(arraySaleHash.length == 0){
        localStorage.setItem("dataShop", "");
      }else{
        let concatArray = arraySaleHash.join("-");
        localStorage.setItem("dataShop", concatArray);
      }
    }
  }

  useEffect(() => {
    let intervalStatus = setInterval(ShopStateUser, 10000);
    async function ShopStateUser() {
      if (loadingFirst) {
        setLoading(true);
        setLoadingFirst(true);
      }
      let shopHashesList = [];
      let closeInterval = true;
      if (localStorage.getItem("dataShop")) {
        let shopData = localStorage.getItem("dataShop");
        try {
          let data = await get(
            `/api/v1/get-sales-by-hash?sales_hashes=${shopData}`
          );
          data.data.data.forEach((element) => {
            if(element.sale_status == "0" || element.sale_status == "4"){
              deleteSaleHash(element.sale_hash);
            }else{
              shopHashesList.push(JSON.parse(element.sale_status_history));
            }
          });
          shopHashesList.forEach((status) => {
            if (status.status == '0' || status.status == '4') {
              closeInterval = true;
            } else {
              closeInterval = false;
              return;
            }
          });
          if (closeInterval) {
            clearInterval(intervalStatus);
          }
          setDataShopInformation({
            ...shopInformationData,
            shopData: shopHashesList,
          });
          setLoading(false);
        } catch (err) {}
      }else{
        setMessage("No hay pedidos en proceso, si deseas ver el historial de tus pedidos y ya iniciaste sesión, ve a la pestaña historial, de lo contrario inicia sesión.")
      }
    }
    ShopStateUser();
    return () => {
      clearInterval(intervalStatus);
    }
    
  }, [loggedUser.logged]);

  if (loading) {
    return <PageLoading />
  }

  return (
    <Fragment>
      <ul className="ul-order-profile">
        {shopInformationData.shopData &&
          shopInformationData.shopData.map((data) => {
            return (
              <li className="row li-order-profile"  key={ shopInformationData.shopData.indexOf(data)}>
                {
                  data && data.map((sale) => {
                    return (
                      <Fragment key={sale.datetime}>
                        {sale.status == 1 ? (
                          <Fragment>
                            <div className="title-footer-info-user col-3">
                              <h4 className="text-center">Recibido</h4>
                              <div className="footer-info-user">
                                <div>
                                  <p className="text-center letter-status-request">Recibimos tu pedido</p>
                                  <p className="text-center letter-status-request">{sale.datetime}</p>
                                </div>
                              </div>
                            </div>
                          </Fragment>
                        ) : (
                          ""
                        )}
                        {sale.status == 2 ? (
                          <Fragment>
                             <div className="separator col-1"></div>
                            <div className="title-footer-info-user col-3">
                              <h4 className="text-center">Aprobado</h4>
                              <div className="footer-info-user">
                                <div>
                                  <p className="text-center letter-status-request">Su pedido está en preparación</p>
                                  <p className="text-center letter-status-request">{sale.datetime}</p>
                                </div>
                              </div>
                            </div>
                          </Fragment>
                        ) : (
                          ""
                        )}
                        {sale.status == 3 ? (
                          <Fragment>
                            <div className="separator col-1"></div>
                            <div className="title-footer-info-user col-3">
                              <h4 className="text-center">Enviado</h4>
                              <div className="footer-info-user">
                                <div>
                                  <p className="text-center letter-status-request">Su pedido ya fue enviado</p>
                                  <p className="text-center letter-status-request">{sale.datetime}</p>
                                </div>
                              </div>
                            </div>
                          </Fragment>
                        ) : (
                          ""
                        )}
                        {/* <hr className="info-user-line-footer" /> */}
                      </Fragment>
                    )
                  })
                }
              </li>
            )
          })
        }
        {message ? <li><div className="alert alert-warning" style={{ color: 'var(--red-macrooster)', background: 'var(--yellow-macrooster)' }}> {message} </div></li>:""}
      </ul>
    </Fragment>
  );
}
