import React, { useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { Nav, Navbar, Dropdown} from "react-bootstrap";
//import { library } from "@fortawesome/fontawesome-svg-core";
import "./css/header.css";
import world from "../assets/images/world.png";
import fb from "../assets/images/fb.png";
import instagram from "../assets/images/instagram.png";
import cartchicken from "../assets/images/cart-chicken.png";
/* import chicken from '../assets/images/chicken.png' */
import logo from "../assets/images/logo.png";
import Modal from "../components/Modal";
import RegisterForm from "./AuthForms/RegisterForm";
import LoginForm from "./AuthForms/LoginForm";
import ForgotPasswordForm from "./AuthForms/ForgotPasswordForm";
// get our fontawesome imports
// import { faSearch } from "@fortawesome/free-solid-svg-icons";
// import { faBars } from "@fortawesome/free-solid-svg-icons";
/* import { faFacebookF } from '@fortawesome/free-brands-svg-icons' 
import { faInstagram } from "@fortawesome/free-solid-svg-icons"; */
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
/* library.add(faFacebookF); 
library.add(faInstagram);  */

function Header(props) {
  const [modalRegister, setModalRegister] = useState({ isOpen: false });
  const [modalLogin, setModalLogin] = useState({ isOpen: false });
  const [modalForgotPassword, setModalForgotPassword] = useState({ isOpen: false });

  const [state, setState] = useState({
    user: props.userData,
    isLoggedIn: props.userIsLoggedIn,
  });
  // 1.2
  function logOut(e) {
    e.preventDefault();
    let appState = {
      isLoggedIn: false,
      user: {},
    };
    localStorage["appState"] = JSON.stringify(appState);
    localStorage.removeItem('id_client');
    localStorage.removeItem('city');
    localStorage.removeItem('shop');
    localStorage.removeItem('dataShop');
    setState(appState);
    props.history.push("/");
    window.location.reload();
  }

  async function handleCloseModalRegister(reload) {
    setModalRegister({ isOpen: false });
    if (reload) {
      window.location.reload();
    }
  }
  function getNavLinkClass(path){
    if(path != '/'){
      return props.location.pathname.includes(path) ? 'active' : '';
    }else{
      return props.location.pathname == path ? 'active' : '';
    }
  }

  function handleOpenModalRegister() {
    setModalRegister({ isOpen: true });
  }

  async function handleCloseModalLogin(reload) {
    setModalLogin({ isOpen: false });
    if (reload) {
      window.location.reload();
    }
  }

  async function handleCloseModalForgotPassword(close) {
    setModalForgotPassword({ isOpen: false });
    if(close){
      setModalLogin({ isOpen: false });
    }
  }

  function handleOpenModalLogin() {
    setModalLogin({ isOpen: true });
  }

  function handleOpenModalForgotPassword() {
    setModalForgotPassword({ isOpen: true });
  }

  return (
    <header className="container">
      <div className="row header-up">
        <div className="col-6">
          <div className="row">
            <div className="col-2 col-sm-3 col-md-3 col-lg-3 col-xl-2 d-flex align-items-center px-0 icons-header">
              <a
                href="#"
                className="text-right-a"
              >
                <img
                  src={world}
                  alt="Mapa"
                  className="img-fluid network-icons"
                />
              </a>
            </div>
            <div className="col-2 col-sm-3 col-md-3 col-lg-3 col-xl-2 d-flex align-items-center px-0 icons-header">
              <a
                href="https://www.facebook.com/macroostercolombia"
                className="border-left"
              >
                <img
                  src={fb}
                  alt="Facebook Macrooster"
                  className="img-fluid network-icons"
                />
              </a>
            </div>
            <div className="col-2 col-sm-3 col-md-3 col-lg-3 col-xl-2 d-flex align-items-center px-0 icons-header">
              <a href="https://www.instagram.com/macrooster_col/?hl=es-la">
                <img
                  src={instagram}
                  alt="Instagram Macrooster"
                  className="img-fluid network-icons instagram"
                />
              </a>
            </div>
          </div>
        </div>

        <div className="col-6 d-flex align-items-center">
          {/* <div className="container"> */}
          <div className="row d-flex align-items-center text-center">

            {state.isLoggedIn ? (
              ""
            ) : (
              <div className="col-12 col-md-5">
                <Link onClick={handleOpenModalRegister}>
                  REGISTRARME
                </Link>
              </div>
            )}
            {state.isLoggedIn ? (
              <div className="col-8 col-xl-5 offset-xl-5  border-right text-center">
                {/* <label className="user-name-info">
                  Hola {state.user.name.split(' ', 1)}
                </label> */}
                <Dropdown className="dropdown-container-user-info justify-content-end">
                <label className="user-name-info">
                  Hola {state.user.name.split(' ', 1)}
                </label>
                  <Dropdown.Toggle split  id="dropdown-custom-2" className="dropdown-information-user"/>
                  <Dropdown.Menu>
                    <Dropdown.Item className="dropdown-item-information-user" href="/profile">Perfil</Dropdown.Item>
                    <Dropdown.Item className="dropdown-item-information-user" href="#" onClick={logOut}>Cerrar sesión</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            ) : (
              <div className="col-12 col-md-5 border-left border-right text-center">
                <Link onClick={handleOpenModalLogin} >
                  INICIAR SESIÓN
                </Link>
              </div>
            )}

            {state.isLoggedIn ? (
              <div className="col-4 col-md-3 col-lg-3 col-xl-2">
                <Link to="/shopping-cart">
                  <img
                    src={cartchicken}
                    alt="Carrito de Compras"
                    className="img-fluid image-header"
                  />
                </Link>
              </div>
            ) : (
              <div className="col-4 offset-4 offset-md-0 col-md-2">
                <Link to="/shopping-cart">
                  <img
                    src={cartchicken}
                    alt="Carrito de Compras"
                    className="img-fluid image-header"
                  />
                </Link>
              </div>
            )}
            {/* <div className="col-2 col-md-2">
              <Link to="/shopping-cart">
                <img
                  src={cartchicken}
                  alt="Carrito de Compras"
                  className="img-fluid image-header"
                />
              </Link>
            </div> */}
          </div>
        </div>
        {/* </div> */}
      </div>
      <Navbar
        collapseOnSelect
        expand="lg"
        className="row header-down principal-nav"
      >
        <div className="col-4 col-sm-2 col-md-2">
          <img
            src={logo}
            alt="Logo Macrooster"
            className="img-fluid logo-nav"
          />
        </div>
        <div className="d-flex align-items-center justify-content-center col-3 col-lg-0 offset-md-7  offset-sm-7  offset-5 offset-lg-0  d-lg-none">
          <Navbar.Toggle />
        </div>
        

        <div className="col-12 col-lg-8 list-nav">
          <Navbar.Collapse>
            <Nav className="mr-auto principal-nav-list"  >
              <Nav.Item  className="mx-2 header-link">
                <Nav.Link className={getNavLinkClass("/")} id="routeHome" eventKey="/" as={Link} to="/" >
                  HOME
                </Nav.Link>
              </Nav.Item>
              <Nav.Item  className="mx-2 header-link">
                <Nav.Link className={getNavLinkClass("/products")} eventKey="/products" id="routeProduct" as={Link} to="/products">
                  PRODUCTOS
                </Nav.Link>
              </Nav.Item>
              <Nav.Item  className="mx-2 header-link">
                <Nav.Link className={getNavLinkClass("/shopping-cart")} id="routeShoppingCart" eventKey="/shopping-cart" id="routeShoppingCart" as={Link} to="/shopping-cart">
                  CARRITO
                </Nav.Link>
              </Nav.Item>
              <Nav.Item  className="mx-2 header-link active">
                <Nav.Link className={getNavLinkClass("/profile")} eventKey="4" as={Link} id="routeProfile" to="/profile">
                  PERFIL
                </Nav.Link>
              </Nav.Item>
            </Nav>
            {/* <Nav.Item className="mx-2 header-link active">
              <div>
                <FontAwesomeIcon icon={faSearch} className="macrooster-icon" />
              </div>
            </Nav.Item>
            <Nav.Item className="mx-2 header-link active">
              <div>
                <FontAwesomeIcon icon={faBars} className="macrooster-icon"/>
              </div>
            </Nav.Item> */}
          </Navbar.Collapse>
        </div>
      </Navbar>
      <div>
        <Modal
          className="register-form"
          isOpen={modalRegister.isOpen}
          onClose={() => handleCloseModalRegister(false)}
        >
          <RegisterForm onSave={handleCloseModalRegister}></RegisterForm>
        </Modal>
        <Modal
          className="login-form"
          isOpen={modalLogin.isOpen}
          onClose={() => handleCloseModalLogin(false)}
        >
          <LoginForm onSave={handleCloseModalLogin} onForgot ={handleOpenModalForgotPassword} onRegister = {handleOpenModalRegister}></LoginForm>
        </Modal>
        <Modal
          className="login-form"
          isOpen={modalForgotPassword.isOpen}
          onClose={() => handleCloseModalForgotPassword(false)}
        >
          <ForgotPasswordForm onSave={handleCloseModalForgotPassword}></ForgotPasswordForm>
        </Modal>
      </div>
    </header>
  );

  /* if(state.isLoggedIn) {
      return (
        <Fragment>
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
          <Link className="navbar-brand" to="/">Macrooster</Link>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
   
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mr-auto">
              <li className="nav-item">
                <Link className="nav-link d-flex align-items-center justify-content-center" to="/presentations"><EggFried/>&nbsp;Presentaciones <span className="sr-only">(current)</span></Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link d-flex align-items-center justify-content-center" to="/users"><PeopleFill/>&nbsp;Usuarios</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link d-flex align-items-center justify-content-center" to="/sales"><ReceiptCutoff/>&nbsp;Ventas</Link>
              </li>
            </ul>
            <ul className="navbar-nav ml-auto">
              <li className="nav-item active">
                <Link className="nav-link d-flex align-items-center justify-content-center" to="/user"><PersonSquare/>&nbsp;{state.user.name}</Link> 
              </li>
              <li>
                <Link className="nav-link d-flex align-items-center justify-content-center" to="/login" onClick={logOut}>Cerrar Sesión</Link>
              </li>
            </ul>
          </div>
        </nav>
        </Fragment>
      )
    } else {
      return <Fragment>
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
          <Link className="navbar-brand" to="/">Macrooster</Link>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
   
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mr-auto">
              <li className="nav-item">
                <Link className="nav-link d-flex align-items-center justify-content-center" to="/presentations"><EggFried/>&nbsp;Presentaciones <span className="sr-only">(current)</span></Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link d-flex align-items-center justify-content-center" to="/users"><PeopleFill/>&nbsp;Usuarios</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link d-flex align-items-center justify-content-center" to="/sales"><ReceiptCutoff/>&nbsp;Ventas</Link>
              </li>
            </ul>
          </div>
        </nav>
        </Fragment>
    } */
}
export default withRouter(Header);
