import React, { Fragment, useEffect, useState } from "react";
import { get } from "../services/httpService";
import "./css/Home.css";

import productos from "../assets/images/productos.png";
import promociones from "../assets/images/promociones.png";
import banner1 from "../assets/images/banner1.png";

import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption
} from 'reactstrap';

import { API_URL } from "../helpers/constants";
const items = [
  {
    src: require('../assets/images/hero.png'),
    altText: 'Slide 1',
    caption: '',
    key: '1'
  },
  {
    src: require('../assets/images/productos.png'),
    altText: 'Slide 2',
    caption: '',
    key: '2'
  },
  // {
  //   src: require('../assets/images/promociones.png'),
  //   altText: 'Slide 3',
  //   caption: '',
  //   key: '3'
  // }
];
export default function HomePage(props) {
  const [categories, setCategories] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const [banners, setBanners] = useState([]);

  async function getBanners() {
    try {
      const response = await get(`/api/v1/get-active-banners`);
      if(response.data){
        response.data.map((banner)=>{
          banners.push({
            src: `${API_URL}/storage/${banner.banner_image}`,
            altText: banner.banner_name,
            category: (banner.category ? banner.category.category_slug : null),
            key: banner.id_banner,
            url: banner.banner_to_url,
            caption: ''
          })
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    let shop_id = props.shop ? props.shop.id_shop : null;
    getBanners(shop_id);
    getCategories(shop_id);
  }, []);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === banners.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  }

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? banners.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  }

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  }

  async function getCategories(shop_id) {
    try {
      const response = await get(`/api/v1/homeCategories?shop_id=${shop_id}`);
      setCategories(response.data);
    } catch (error) {
      console.log(error);
    }
  }

  

  function handleCategoryClick(category) {
    props.history.push(`/products/${category.category_slug}`);
  }

  const slides = banners.map((item) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.key}
      >
        {item.category?
         (
         <a href={`products/${item.category}`}>
           <img src={item.src} alt={item.altText} height="auto" width="100%" className="carousel" />
          </a>
          ):(
          <a href={`https://${item.url}`} target="_blank">
            <img src={item.src} alt={item.altText} height="auto" width="100%" className="carousel" />
          </a>
          )}
        <CarouselCaption captionText={item.caption} captionHeader={item.caption} />
      </CarouselItem>
    );
  });

  return (
    <Fragment>

      <Carousel
        activeIndex={activeIndex}
        next={next}
        previous={previous}
        interval={10000}
      >
        <CarouselIndicators items={banners} activeIndex={activeIndex} onClickHandler={goToIndex} />
        {slides}
        <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
        <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
      </Carousel>


      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <img src={productos} alt="" className="img-fluid" />
          </div>
        </div>
      </div>
      <div className="container">
        {/*            <div className="container">
        <div id="carouselExampleControls" className="carousel slide carousel-banner" data-ride="carousel">
            <div className="carousel-inner">
                <div className="carousel-item active">
                    <img className="d-block w-100" src={hero} alt="First slide"/>
                </div>
            <div className="carousel-item">
                    <img className="d-block w-100" src="..." alt="Second slide"/>
                </div>
            <div className="carousel-item">
                    <img className="d-block w-100" src="..." alt="Third slide"/>
            </div>
        </div>
            <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="sr-only">Previous</span>
             </a>
            <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                 <span className="sr-only">Next</span>
            </a>
        </div>    
            </div> */}

        <div className="container">

          <div className="row">
            {categories &&
              categories.map((category) => {
                return (
                  <div
                    className="col-12 col-sm-6 col-md-4 mb-4 pt-2 product-image-home"
                    key={category.id_category}
                    onClick={() => handleCategoryClick(category)}
                  >
                    <h3 className="text-center category-title">
                      {category.category_name.toUpperCase()}
                    </h3>
                    <img
                      src={`${API_URL}/storage/${category.category_image}`}
                      alt={category.category_name}
                      className="img-fluid"
                    />
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </Fragment>
  );
}
