import React, { useState } from "react";
import { QueryPost } from '../services/httpService';
import "./css/Register.css";

export default function RegisterAddress(props) {
  let [form, setForm] = useState({
    address: "",
    city_id: localStorage.getItem("city"),
    user: JSON.parse(localStorage.getItem("appState")),
  });
  function handleChange(e) {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  }

  async function handleChangeInformation(e) {
    e.preventDefault();
    let user_id = localStorage.getItem('id_client')
    let city_id = JSON.parse(form.city_id)
    let json = await QueryPost(`/api/v1/add-client-address/${user_id}`, {
      address: form.address,
      city_id: city_id.id_city,
    });
    props.onSave(json.data.success);
  }
  return (
    <form className="form login">
      <div className="title-login text-center title-direction">
        <h1>Ingresa tu dirección</h1>
      </div>
      <div className="form-group">
        <input
          type="text"
          className="form-control input-register login"
          id="address"
          name="address"
          onChange={handleChange}
          value={form.address}
          placeholder="Dirección"
          required
        />
      </div>
      <button
        type="submit"
        className="btn button-send-information button-font-style-large"
        onClick={handleChangeInformation}
      >
        Enviar información
      </button>
    </form>
  );
}
