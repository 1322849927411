import React, { useState } from 'react';

export default function FastShop(props) {


    let [form, setForm] = useState({
        name: '',
        address: '',
        phone:''
    })

    function handleChange(e) {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    function handleChangeInformation(e){
        e.preventDefault()
        if (form.name && form.address && form.phone) {
            if (localStorage.getItem("LocalPresentations") !== null &&
                localStorage.getItem("LocalPresentations") !== undefined) {
                let localStorageData = JSON.parse(
                  localStorage.getItem("LocalPresentations")
                );
                localStorageData.sale_information.client_name = form.name;
                localStorageData.sale_information.address = form.address;
                localStorageData.sale_information.phone = form.phone;
                localStorage.setItem(
                  "LocalPresentations",
                  JSON.stringify(localStorageData)
                );
              } else {
                    let localStorageData = {};
                    localStorageData.sale_information.client_name = form.name;
                    localStorageData.sale_information.address = form.address;
                    localStorageData.sale_information.phone = form.phone;
                    localStorage.setItem(
                    "LocalPresentations",
                    JSON.stringify(localStorageData)
                    );
                }
            props.onSave(true)
        }else{
            props.onSave(true)
        }

        
    }


    return (
        <form onSubmit={handleChangeInformation}  className="form login">
            <div className="title-login text-center">
                <h1>Ingresa tus datos</h1>
            </div>
            <div className="form-group">
                <input type="text" className="form-control input-register login" id="name" name="name" onChange={handleChange} value={form.name} placeholder="Nombre" required />
            </div>
            <div className="form-group">
                <input type="text" className="form-control input-register login" id="address" name="address" onChange={handleChange} value={form.address} placeholder="Dirección" required />
            </div>
            <div className="form-group">
                <input type="number" className="form-control input-register login" id="phone" name="phone" onChange={handleChange} value={form.phone} placeholder="Telefono" required />
            </div>
            <button type="submit" className="btn button-send-information not-logged-user button-font-style" >Guardar datos</button>
        </form>
    )
}