import React, { useEffect, useState } from "react";
import "../css/ProductDetails.css";
import chicken from "../../assets/images/pollocaja.png";
import cartChicken from "../../assets/images/cart-chicken.png";
import { Cpu } from "react-bootstrap-icons";
import { API_URL } from "../../helpers/constants";

export default function ProductDetails(props) {
  const shopId = props.presentation.shops[0].id_shop;
  const shopDeliveryValue = props.presentation.shops[0].shop_home_delivery_value;
  const [messageErrorRequired, setMessageErrorRequired] = useState(false); //Campo con mensaje de componente requerido
  let [presentation, setPresentation] = useState({
    id_presentation_shop:
      props.presentation.shops[0].pivot.id_presentation_shop, // Id de la presentation_shop
    presentation_name: props.presentation.presentation_name,
    presentation_description: props.presentation.presentation_description,
    presentation_unit_value:
      props.presentation.shops[0].pivot.presentation_shop_value,
    presentation_quantity: 1,
    presentation_image: props.presentation.presentation_image,
    component_options: [],
    components: mapComponents(props.presentation.components),
    components_selected: []
  });

  function getCurrentShop(){
    let appLocalStorageShop = localStorage.shop
    if (appLocalStorageShop) {
        let AppStateShop = JSON.parse(appLocalStorageShop)
        return AppStateShop.id_shop
    }
    return undefined
  }

  function mapComponents(components) {
    const shopId = getCurrentShop()
    /* Primero es necesario obtener la ciudad */
    if (components !== null && components !== undefined && shopId) {
      return components.map((component) => {
        return {
          ...component,
          component_options: component.component_options.map(
            (component_option) => {
              /* Obtener el valor del componente dependiendo de la ciudad */
              let componentShopConfig = JSON.parse(component_option.component_option_shop_information)[shopId]
              if( !(componentShopConfig) ){
                /* Si por algún motivo la ciudad no tiene configurada la opción de componente, se deja en 0 e inactiva*/
                componentShopConfig={component_option_value:0, component_option_status: false}
              }
              /* component_option_status, component_option_value */
              if([1,2].includes(component.component_selection_type)){
                return { ...component_option, checked: false, component_option_quantity:0 , component_option_value: componentShopConfig.component_option_value, component_option_status: componentShopConfig.component_option_status}
              } else {
                return { ...component_option, component_option_quantity: 0, component_option_value: componentShopConfig.component_option_value, component_option_status: componentShopConfig.component_option_status}
              }
            }
          ),
        };
      });
    }
  }

  function handleChangeQuantityIncrease(e) {
    setPresentation({
      ...presentation,
      presentation_quantity: ++presentation.presentation_quantity,
    });
  }

  function handleChangeQuantityDecrease(e) {
    if (presentation.presentation_quantity - 1 >= 1) {
      setPresentation({
        ...presentation,
        presentation_quantity: --presentation.presentation_quantity,
      });
    }
  }
  function handleOnClickRadio(e){
    const id_component = Number(e.target.dataset.id_component);
    const component = presentation.components.filter((component) => {
      return component.id_component === id_component;
    });
    if (e.target.checked == true){ //Si ya fué seleccionado el radio button.
      e.target.checked = !e.target.checked;
      if([1,2].includes(component[0].component_selection_type)){ //Si es tipo 1 o 2 el componente.
      component[0].component_options.map(
        (component_option) => {
           component_option.component_option_quantity = (e.target.checked)? 1 : 0;
           component_option.checked = e.target.checked;
        }
      );
    }
    }
  }
  function handleChangeForm(e) {
    const id_component = Number(e.target.dataset.id_component);
    const id_component_option = Number(e.target.dataset.id_component_option);
    const component = presentation.components.filter((component) => {
      return component.id_component === id_component;
    });
    const min_option = component[0].component_minimum_quantity; //Obtener el valor mínimo para la opción
    const max_option = component[0].component_maximum_quantity; //Obtener el valor máximo para la opción
    let new_component_option = component[0].component_options.filter(
      (component_option) => {
        return component_option.id_component_option === id_component_option;
      }
    );
    if([1].includes(component[0].component_selection_type)){ //Si es tipo 1, hace un espejo con su variable checked
      e.target.checked = e.target.checked == "true" ? "false" : "true";
    }
    new_component_option = new_component_option[0];
    /* Condicionar aqui */
    if([1,2].includes(component[0].component_selection_type)){
      new_component_option.checked = e.target.checked;
      new_component_option.component_option_quantity = (e.target.checked)? 1 : 0;
    } else if(component[0].component_selection_type===3){
      if(e.target.dataset.type === "subtract"){
        if (new_component_option.component_option_quantity>=1
          && new_component_option.component_option_quantity > min_option){
          new_component_option.component_option_quantity--;
        }
      } else if (e.target.dataset.type === "add"){
        if (new_component_option.component_option_quantity < max_option){
          new_component_option.component_option_quantity++;
        }
      }
    }
    
    let new_components = presentation.components.map((component) => {
      if (component.id_component == id_component) {
        let component_options = component.component_options.map(
          (component_option) => {
            if (component_option.id_component_option === id_component_option) {
              return new_component_option;
            }
            if(component.component_selection_type === 1){
              //Si es radiobutton y no está "checked" colocar cantidad en 0 y checked en false
              return {...component_option, component_option_quantity: 0, checked: false }
            } 
            return component_option;
          }
        );
        return { ...component, component_options: component_options };
      }
      return component;
    });

    setPresentation({
      ...presentation,
      components: new_components,
    });
  }

  function handleOnClickAddPresentation() {
    let final_component_options = [];
    let final_components = []
    let totalComponentPrice = 0;
    if (
      presentation.components !== null &&
      presentation.components !== undefined &&
      presentation.components.length != 0
    ) {
      final_components = presentation.components.map((component) => {
        let new_component_options = component.component_options.filter((component_option) => {
          return component_option.component_option_quantity>0; /* Si es cantidad seleccionable, revisar que sea mayor que 1 para enviarlo */
        });
        return {...component, component_options: new_component_options}
      });
      final_components = final_components.filter((component)=>{
        return component.component_options.length>0
      })
      final_component_options = presentation.components.map((component) => {
        return component.component_options.filter((component_option) => {
          return component_option.component_option_quantity>0; /* Si es cantidad seleccionable, revisar que sea mayor que 1 para enviarlo */
        });
      });
      final_component_options = final_component_options
        .reduce((co1, co2) => {
          return co1.concat(co2);
        })
        .map((component_option) => {
          return {
            id_component_option: component_option.id_component_option,
            component_option_unit_value: component_option.component_option_value, //Id de la opción de componente
            component_option_name: component_option.component_option_name,
            component_option_quantity: component_option.component_option_quantity,
          };
        });
      final_component_options.forEach((component_price) => {
        totalComponentPrice += parseInt(
          component_price.component_option_unit_value * component_price.component_option_quantity
        );
      });
    } else {
      final_component_options = [];
    }
    if (
      final_component_options == null ||
      final_component_options == undefined
    ) {
      final_component_options = [];
    }

    let localPresentation = {
      sale_information: {
        id_shop: shopId,
        observations: "",
        address: "",
        phone: "",
        client_name: "",
        shop_delivery_value: shopDeliveryValue,
        presentations: [],
      },
    };
    let observationsLocalPresentations = {
      id_presentation_shop: presentation.id_presentation_shop,
      presentation_name: presentation.presentation_name,
      presentation_unit_value: presentation.presentation_unit_value,
      presentation_quantity: presentation.presentation_quantity,
      presentationTotalValue:
        (presentation.presentation_unit_value + totalComponentPrice) *
        presentation.presentation_quantity,
      observations: "",
      component_options: final_component_options,
      components: final_components
    };
    localPresentation.sale_information.presentations.push(
      observationsLocalPresentations
    );
    if (
      localStorage.getItem("LocalPresentations") !== null &&
      localStorage.getItem("LocalPresentations") !== undefined
    ) {
      let localStorageData = JSON.parse(
        localStorage.getItem("LocalPresentations")
      );
      localStorageData.sale_information.presentations.push(
        observationsLocalPresentations
      );
      localStorage.setItem(
        "LocalPresentations",
        JSON.stringify(localStorageData)
      );
    } else {
      localStorage.setItem(
        "LocalPresentations",
        JSON.stringify(localPresentation)
      );
    }
    let checkRequired = true;
    presentation.components.map((component) =>{
      if(component.component_mandatory && checkRequired){
        switch (component.component_selection_type){
          case 1:
            let localCheckedRadio = false;
            component.component_options.map((component_option)=>{
              if(component_option.checked)
                localCheckedRadio = true;
            });
            checkRequired = localCheckedRadio;
            break;
          case 2:
            let localCheckedCheckBox = false;
            component.component_options.map((component_option)=>{
              if(component_option.checked)
                localCheckedCheckBox = true;
            });
            checkRequired = localCheckedCheckBox;
            break;
          case 3:
            component.component_options.map((component_option)=>{
              if(component_option.component_option_quantity < 1 )
                checkRequired = false;
            });
            break;
        } 
      }
    });

    if(checkRequired){
      setPresentation({
        ...presentation,
        component_options: final_component_options,
        components: final_components
      });
      props.onSave(true);
    }else{
      setMessageErrorRequired('¡Recuerda llenar todos los componentes obligatorios!');
    }
  }

  return (
    <div className="product-details-form">
      <div className="header-product-detail">
        <div className="row">
          <div className="col-6 container image-product-details">
            <img src={`${API_URL}/storage/${presentation.presentation_image}`} alt="" className="img-fluid" />
          </div>
          <div className="col-6 container-product-detail">
            <div className="text-header-product-details">
              <h5>{presentation.presentation_name}</h5>
              <p>{presentation.presentation_description}</p>
            </div>
            <div className="product-info-change">
              <div className="product-quantity-button">
                <div className="list-quantity-products m-0">
                  <ul className="m-0">
                    <li className="symbol-product-detail">
                      <span
                        className="quantity-change"
                        name="presentation_quantity"
                        onClick={handleChangeQuantityDecrease}
                      >
                        -
                      </span>
                    </li>
                    <li className="product-quantity-change">
                      {presentation.presentation_quantity}
                    </li>
                    <li className="symbol-product-detail">
                      <span
                        className="quantity-change"
                        href="#"
                        name="presentation_quantity"
                        onClick={handleChangeQuantityIncrease}
                      >
                        +
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="product-quantity-button chicken">
                <div className="list-quantity-products symbol-product-container">
                  <ul className="d-flex symbol-product-list">
                    <li className="symbol-product-detail button">
                      {new Intl.NumberFormat("es-CO", {
                        style: "currency",
                        currency: "COP",
                        minimumFractionDigits: 0,
                      }).format(
                        presentation.presentation_unit_value
                          ? presentation.presentation_unit_value
                          : 0
                      )}
                    </li>
                    <li className="product-quantity-change image">
                      <img
                        src={cartChicken}
                        alt=""
                        className="img-fluid chicken-img"
                      />
                    </li>
                    <li className="symbol-product-detail button">Agregar</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr className="separator-product-details" />
      {/* {presentation.components && presentation.components.length === 0 && (
        <div className="col-12 alert alert-danger"></div>
      )} */}
      {presentation.components &&
        presentation.components.map((component) => {
          return ( component.component_status &&
            <div className="body-product-detail" key={component.id_component}>
              <div className="title-product-detail">
                <h5>{component.component_name.toUpperCase()} {(component.component_mandatory && '(Obligatorio)')}</h5>
              </div>
              {/* Tipo de selección única */}
              {component.component_options && component.component_selection_type === 1 &&
                component.component_options.map((component_option) => {
                  return ( component_option.component_option_status &&
                    <div
                      className="option-product-details"
                      key={component_option.id_component_option}
                    >
                      <ul className="d-flex component-option-list">
                        <li className="symbol-product-detail list-details-check">
                          <input
                            name={`component-option-radio-${component.id_component}`}
                            type="radio"
                            checked={component_option.checked}
                            className="form-check details-check"
                            data-id_component_option={
                              component_option.id_component_option
                            }
                            data-id_component={component.id_component}
                            onClick={handleOnClickRadio}
                            onChange={handleChangeForm}
                          />
                        </li>
                        <li className="symbol-product-detail list-details">
                          <label className="form-check-label message-check details-message">
                            {component_option.component_option_name}
                          </label>
                        </li>
                        <li className="symbol-product-detail list-details">
                          <label className="form-check-label message-check details-message">
                            {new Intl.NumberFormat("es-CO", {
                              style: "currency",
                              currency: "COP",
                              minimumFractionDigits: 0,
                            }).format(
                              component_option.component_option_value
                                ? component_option.component_option_value
                                : 0
                            )}
                          </label>
                        </li>
                      </ul>
                    </div>
                  );
              })}
              {/* Tipo de selección múltiple */}
              {component.component_options && component.component_selection_type === 2 &&
                component.component_options.map((component_option) => {
                  return ( component_option.component_option_status &&
                    <div
                      className="option-product-details"
                      key={component_option.id_component_option}
                    >
                      <ul className="d-flex component-option-list">
                        <li className="symbol-product-detail list-details-check">
                          <input
                            name={`component-option-checkbox-${component.id_component}`}
                            type="checkbox"
                            checked={component_option.checked}
                            className="form-check details-check"
                            data-id_component_option={
                              component_option.id_component_option
                            }
                            data-id_component={component.id_component}
                            onChange={handleChangeForm}
                          />
                        </li>
                        <li className="symbol-product-detail list-details">
                          <label className="form-check-label message-check details-message">
                            {component_option.component_option_name}
                          </label>
                        </li>
                        <li className="symbol-product-detail list-details">
                          <label className="form-check-label message-check details-message">
                            {new Intl.NumberFormat("es-CO", {
                              style: "currency",
                              currency: "COP",
                              minimumFractionDigits: 0,
                            }).format(
                              component_option.component_option_value
                                ? component_option.component_option_value
                                : 0
                            )}
                          </label>
                        </li>
                      </ul>
                    </div>
                  );
                })}
                {/* Tipo de selección: Cantidad seleccionable */}
              {component.component_options && component.component_selection_type === 3 &&
                component.component_options.map((component_option) => {
                  return ( component_option.component_option_status &&
                    <div
                      className="option-product-details"
                      key={component_option.id_component_option}
                    >
                      <ul className="d-flex component-option-list">
                        <li className="symbol-product-detail list-details">
                          <label className="form-check-label message-check details-message">
                            {component_option.component_option_name} ( {new Intl.NumberFormat("es-CO", {
                              style: "currency",
                              currency: "COP",
                              minimumFractionDigits: 0,
                            }).format(
                              component_option.component_option_value
                                ? component_option.component_option_value
                                : 0
                            )})
                          </label>
                        </li>
                        {/* OJO AQUÍ: CAMBIAR */}
                        <li className="symbol-product-detail">
                          <span
                            className="quantity-change"
                            name={`component-option-quantity-${component_option.id_component_option}`}
                            onClick={handleChangeForm}
                            data-type="subtract"
                            data-id_component_option={
                              component_option.id_component_option
                            }
                            data-id_component={component.id_component}
                          >
                            -
                          </span>
                        </li>
                        <li className="product-quantity-change">
                          {component_option.component_option_quantity}
                        </li>
                        <li className="symbol-product-detail">
                          <span
                            className="quantity-change"
                            name={`component-option-quantity-${component_option.id_component_option}`}
                            onClick={handleChangeForm}
                            data-type="add"
                            data-id_component_option={
                              component_option.id_component_option
                            }
                            data-id_component={component.id_component}
                          >
                            +
                          </span>
                        </li>
                        <li className="symbol-product-detail list-details">
                          <label className="form-check-label message-check details-message">
                            {new Intl.NumberFormat("es-CO", {
                              style: "currency",
                              currency: "COP",
                              minimumFractionDigits: 0,
                            }).format(
                              component_option.component_option_value
                                ? component_option.component_option_value * component_option.component_option_quantity
                                : 0
                            )}
                          </label>
                        </li>
                      </ul>
                    </div>
                  );
                })}
              <hr className="separator-product-details" />
            </div>
          );
        })}
        {messageErrorRequired ? <div className="alert alert-danger text-center"> {messageErrorRequired} </div> : ''}
      <button
        className="btn button-send-shopping-cart"
        onClick={handleOnClickAddPresentation}
      >
        Agregar
      </button>
    </div>
  );
}
