import React from 'react';
import './css/App.css';
import {BrowserRouter, Switch, Route} from 'react-router-dom';
import HomePage from '../pages/HomePage';
import ShoppingCart from '../pages/ShoppingCart';
import Layout from './Layout'
import Products from '../pages/Products';
import Profile from '../pages/Profile';
import ResetPasswordForm from './AuthForms/ResetPasswordForm';

function App() {
  const AppRoute = ({ component: Component, layout: Layout, ...rest }) => (
    <Route {...rest} render={props => (
      <Layout>
        <Component {...props} />
      </Layout>
    )} />
  )
  return (
    <BrowserRouter>
      {/* <Layout> */}
        <Switch>
            {/* Home */}
            <AppRoute exact path="/" layout={Layout} component={HomePage} />
            
            <AppRoute exact path="/shopping-cart" layout={Layout} component={ShoppingCart} />
            <AppRoute exact path="/products/:category?" layout={Layout} component={Products} />
            <AppRoute exact path="/profile" layout={Layout} component={Profile} />
            <AppRoute exact path="/password/:token?" layout={Layout} component={ResetPasswordForm} />
        </Switch>
      {/* </Layout> */}
    </BrowserRouter>
  )
}

export default App;
