import React, { useState } from 'react';
import { QueryPost } from '../services/httpService';
import "./css/Register.css";

export default function RegisterPhone(props){

    let [form, setForm] = useState({
        phone:''
    });
    // let [user_info, setUserInfo] = useState({
    //     user: JSON.parse(sessionStorage.getItem('appState'))
    // })
    function handleChange(e) {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    async function handleChangeInformation(e){
        e.preventDefault();
        let user_id = localStorage.getItem('id_client')
        let json = await QueryPost(
            `/api/v1/add-client-phone/${user_id}`,
            {phone:form.phone}
        );
        props.onSave(json.data.success);
    }
    return (
        <form className="form login">
            <div className="title-login text-center title-direction">
                <h1>Ingresa tu telefono</h1>
            </div>
            <div className="form-group">
                <input type="number" className="form-control input-register login" id="phone" name="phone" onChange={handleChange} value={form.phone} placeholder="Telefono" required />
            </div>
            <button
                type="submit"
                className="btn button-send-information button-font-style-large"
                onClick={handleChangeInformation}
            >
                Enviar información
            </button>
        </form>
    )
}
