import React from "react";
import { Fragment, useEffect, useState } from "react";
import { QueryGet } from "../../services/httpService";
import PageLoading from '../PageLoading';

export default function UserInformation(props) {
  const [loading, setLoading] = useState(false);
  let [userInformation, setInformation] = useState({
    addressesUser: [],
    phonesUSer: [],
  });
  let [loggedUser] = useState({
    logged: props.userLogged,
  });
  useEffect(() => {
    const loadUserInfo = async () => {
      setLoading(true)
      try {
        let data = await QueryGet("/api/v1/get-client-by-logged-user");
        let clientAddres = JSON.parse(data.data.client_addresses);
        let clientPhones = JSON.parse(data.data.client_phones);
        let datos3 = clientAddres.addresses.map((ad) => {
          return ad.address;
        });
        let datos2 = clientPhones.phones.map((dt) => {
          return dt;
        });
        setInformation({
          addressesUser: datos3,
          phonesUSer: datos2,
        });
        setLoading(false)
      } catch (err) {
        setLoading(false)
      }
    };
    loadUserInfo();
  }, [loggedUser.logged]);

  if (loading) {
    return <PageLoading />
  }

  return (
    <Fragment>
      <div className="form-group col-12">
        <label>Direcciones</label>
        <div className="list-group">
          {userInformation.addressesUser &&
            userInformation.addressesUser.map((dato) => {
              return (
                <a
                  href="#"
                  className="list-group-item infor-user"
                  key={dato}
                  value={dato}
                  name="address"
                  data-id_address={dato}
                  id="address"
                >
                  {dato}
                </a>
              );
            })}
        </div>
      </div>
      <div className="form-group col-12">
        <label>Telefonos</label>
        <div className="list-group">
          {userInformation.phonesUSer &&
            userInformation.phonesUSer.map((dato) => {
              return(
                <a
                href="#"
                className="list-group-item infor-user"
                key={dato}
                value={dato}
                name="phone"
                data-id_address={dato}
                id="phone"
              >
                {dato}
              </a>
              );
            })}
        </div>
      </div>
    </Fragment>
  );
}
